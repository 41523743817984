import React from 'react';

type NumberType = {
  title: string;
  name: string;
  value?: number;
  placeholder?: string;
  validation?: {
    regex?: string;
    min?: number;
    max?: number;
    minLength?: number;
    maxLength?: number;
  };
  required?: boolean;
  addClass?: string;
};

type NumberInputProps = NumberType & {
  updateSingleField: (fields: Partial<NumberType>) => void;
};

export default function NumberInput({
  title,
  name,
  placeholder,
  validation,
  required,
  addClass,
  value,
  updateSingleField,
}: NumberInputProps) {
  return (
    <div className={`input input__number ${addClass}`}>
      <label htmlFor={name}>
        <input
          type="number"
          name={name}
          id={name}
          value={value}
          placeholder={placeholder}
          min={validation?.min || 0}
          max={validation?.max || 1000}
          minLength={validation?.minLength || 0}
          maxLength={validation?.maxLength || 50}
          required={required}
          onChange={(e) => updateSingleField({ [name]: e.target.value })}
        />
        {title}
      </label>
    </div>
  );
}
