import React from 'react';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import ImgButton from '../components/ImgButton';

export default function Legal() {
  const { t } = useTranslation('', { keyPrefix: 'Legal' });

  return (
    <main id="cga" className="lpd">
      <Hero
        page="Legal"
        imgPath="../assets/layouts/team-cover.jpg"
        panorama="panorama-light-green"
      />

      <section className="container medTight">
        <h2>{t(`section1.title`)}</h2>

        <p>
          <h5 style={{ textAlign: 'left', margin: '0px' }}>{t(`section1.text1h5`)}</h5>
          {t(`section1.text1`)}
        </p>

        <p>
          <h5 style={{ textAlign: 'left', margin: '0px' }}>{t(`section1.text2h5`)}</h5>
          {t(`section1.text2`)}
        </p>
      </section>

      <section className="container medTight">
        <h2>{t(`section2.title`)}</h2>

        <p>{t(`section2.text1`)}</p>
        <p>{t(`section2.text2`)}</p>
        <p>{t(`section2.text3`)}</p>
      </section>

      <section className="container medTight">
        <h2>{t(`section3.title`)}</h2>

        <p>{t(`section3.text1`)}</p>
      </section>

      <section className="container medTight">
        <h2>{t(`section4.title`)}</h2>

        <p>{t(`section4.text1`)}</p>

        <h5 style={{ textAlign: 'left' }}>{t(`section4.h5`)}</h5>

        <p>
          <a href="https://les-eterpaz.ch" target="_blank" rel="noopener noreferrer">
            {t(`section4.link1`)}
          </a>
          <br />
          <a href="https://redoxdigital.ch" target="_blank" rel="noopener noreferrer">
            {t(`section4.link2`)}
          </a>
        </p>

        <ImgButton to="/" label={t('link')} picto="maison_white" btnType="btn--primary" />
      </section>
    </main>
  );
}
