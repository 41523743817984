import React from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import { Link } from 'react-router-dom';

interface LeafMapProps {
  center: [number, number];
  markers: { id: number; position: [number, number]; imgPath: string }[];
  zoom?: number;
}

export default function LeafMap(props: LeafMapProps) {
  const { t } = useTranslation('', { keyPrefix: 'Map' });

  const { center, markers, zoom } = props;

  return (
    <MapContainer id="map" center={center} zoom={zoom || 12} scrollWheelZoom={false}>
      <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
      {markers?.map((item) => (
        <Marker
          position={item.position}
          icon={
            new L.Icon({
              iconUrl: item.imgPath,
              iconSize: [50, 50],
              iconAnchor: [25, 25],
              popupAnchor: [0, -30],
            })
          }
          key={item.id}
        >
          <Popup>
            {t(`${item.id}.website`) ? (
              <Link to={t(`${item.id}.website`) || '#'} target="_blank">
                {t(`${item.id}.desc`)}
              </Link>
            ) : (
              t(`${item.id}.desc`)
            )}
          </Popup>
        </Marker>
      ))}
    </MapContainer>
  );
}
