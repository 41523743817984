import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type BooleanInputType = {
  title: string;
  name: string;
  subtitle?: string;
  initValue?: boolean;
  twoLined?: boolean;
  title2?: string;
  subtitle2?: string;
};

type BooleanInputProps = BooleanInputType & {
  updateSingleField: (fields: Partial<BooleanInputType>) => void;
};

export default function RadioInput({
  title,
  name,
  initValue,
  subtitle,
  twoLined,
  title2,
  subtitle2,
  updateSingleField,
}: BooleanInputProps) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });
  const [selectedRadio, setSelectedRadio] = useState<boolean>(initValue || false);

  const radioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSingleField({ [name]: e.target.value === 'yes' });
    setSelectedRadio(e.target.value === 'yes');
  };

  return (
    <div className="input input__radio input__radio--boolean">
      {twoLined ? (
        <legend>
          {title}
          {subtitle !== null && <small> {subtitle}</small>}
          <br />
          {title2}
          {subtitle2 !== null && <small> {subtitle2}</small>}
        </legend>
      ) : (
        <legend>
          {title}
          {subtitle !== null && (
            <>
              <br />
              <small>{subtitle}</small>
            </>
          )}
        </legend>
      )}

      <label htmlFor={`${name}-false`} className={`${!selectedRadio ? 'checked' : ''}`}>
        <input
          type="radio"
          name={name}
          id={`${name}-false`}
          value="no"
          checked={!selectedRadio}
          onChange={radioHandler}
        />
        {t('no')}
      </label>

      <label htmlFor={`${name}-true`} className={`${selectedRadio ? 'checked' : ''}`}>
        <input
          type="radio"
          name={name}
          id={`${name}-true`}
          value="yes"
          checked={selectedRadio}
          onChange={radioHandler}
        />
        {t('yes')}
      </label>
    </div>
  );
}
