import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Panorama from './Panorama';
import Cta from './Cta';

type FooterPropsType = {
  contactFormUrl?: string;
};

export default function Footer({ contactFormUrl }: FooterPropsType) {
  const { t } = useTranslation('');
  const location = useLocation();

  return (
    <>
      {location.pathname !== contactFormUrl && (
        <Cta
          tagId="footerTop"
          to="/configurator"
          label={t('Footer.CTA.btn')}
          picto="fa-calendar-days"
          btnType="btn--white"
          title={t('Footer.CTA.title')}
          surtitle={t('Footer.CTA.surtitle')}
        />
      )}
      <Panorama img="panorama-brown" />
      <footer id="footer">
        <div id="footerMain" className="container">
          <div className="row">
            <div className="footerMainCol">
              <h6>{t('Footer.navigation')}</h6>
              <ul>
                <li>
                  <Link to="/home">{t('MainMenu.home')}</Link>
                </li>
                <li>
                  <Link to="/camp">{t('MainMenu.camp')}</Link>
                </li>
                <li>
                  <Link to="/activities">{t('MainMenu.activities')}</Link>
                </li>
                <li>
                  <Link to="/team">{t('MainMenu.team')}</Link>
                </li>
              </ul>
            </div>
            <div className="footerMainCol">
              <h6>{t('Footer.infosAndReservations')}</h6>
              <ul>
                <li>
                  <a
                    href="tel:+41793101489"
                    title="Contact par téléphone"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span id="phone" />
                  </a>
                </li>
                <li>
                  <a
                    href="mailto:contact@les-eterpaz.ch"
                    title="Contact par e-mail"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span id="mail" />
                  </a>
                </li>
              </ul>
              <br />
            </div>
            <div className="footerMainCol">
              <h6>{t('Footer.checkoutAndConcierge')}</h6>
              <ul>
                <li>
                  <a
                    href="tel:+41 76 476 30 95"
                    title="Contact par téléphone"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +41 76 476 30 95
                  </a>
                </li>
              </ul>

              <br />

              <h6>{t('Footer.phoneBooth')}</h6>
              <ul>
                <li>
                  <a
                    href="tel:+41 26 921 30 95"
                    title="Contact par téléphone"
                    target="_blank"
                    rel="noreferrer"
                  >
                    +41 26 921 30 95
                  </a>
                </li>
              </ul>
            </div>

            <div className="footerMainCol">
              <h6>{t('Footer.address')}</h6>
              <a
                href="https://www.google.com/maps/dir//Les+Eterpaz,+1636+Broc/"
                target="_blank"
                rel="noreferrer"
                title="Itinéraire"
              >
                Colonie Les Eterpaz
                <br />
                Rte des Marches 11
                <br />
                CH-1636 Broc
              </a>
            </div>
          </div>
        </div>
        <hr />
        <div id="footerBottom">
          <small>
            <Link to="/cga">{t('Footer.conditions')}</Link> |{' '}
            <Link to="/protection">{t('Footer.lpd')}</Link> |{' '}
            <Link to="/legal">{t('Footer.legal')}</Link>
          </small>
          <small>
            &copy; Les Eterpaz {new Date().getFullYear()}. {t('Footer.createdBy')}&nbsp;
            <a href="https://redoxdigital.ch" target="_blank" rel="noopener noreferrer">
              Redox Digital
            </a>
          </small>
        </div>
      </footer>
    </>
  );
}
