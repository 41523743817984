import { FormInputsType } from '../input/FormType';
import { DateDifference } from './DateHelper';

export function getTotalPeople(d: FormInputsType): number {
  return +d.kidsNb + +d.teensNb + +d.adultsNb;
}

export function getAnimationsPrice(d: FormInputsType): number {
  let incr = 0;

  const sportSuppl = 10;
  const natureSuppl = 10;
  const gamesSuppl = 5;

  if (d.animations_bow) incr += +sportSuppl * (+d.kidsNb + +d.teensNb);
  if (d.animations_mtb) incr += +sportSuppl * (+d.kidsNb + +d.teensNb);
  if (d.animations_nature) incr += +natureSuppl * (+d.kidsNb + +d.teensNb);
  if (d.animations_forest) incr += +natureSuppl * (+d.kidsNb + +d.teensNb);
  if (d.animations_biathlon) incr += +gamesSuppl * (+d.kidsNb + +d.teensNb);
  if (d.animations_olympiade) incr += +gamesSuppl * (+d.kidsNb + +d.teensNb);
  if (d.animations_jeuDeLOie) incr += +gamesSuppl * (+d.kidsNb + +d.teensNb);
  return incr;
}

export function getAnimationsNb(d: FormInputsType): number {
  let incr = 0;
  if (d.animations_bow) incr += 1;
  if (d.animations_mtb) incr += 1;
  if (d.animations_nature) incr += 1;
  if (d.animations_forest) incr += 1;
  if (d.animations_biathlon) incr += 1;
  if (d.animations_olympiade) incr += 1;
  if (d.animations_jeuDeLOie) incr += 1;
  return incr;
}

export function getVisitsPrice(d: FormInputsType): number {
  let incr = 0;
  if (d.visits_maisonDuGruyere) incr += +3 * (+d.kidsNb + +d.teensNb);
  if (d.visits_maisonCailler && d.activityPack !== 'classique')
    incr += +7 * (+d.kidsNb + +d.teensNb);
  if (d.visits_chateauDeGruyere) {
    if (d.visits_chateauDeGruyereDetails === 'guided') incr += +90 * d.visits_classNb;
    if (
      d.visits_chateauDeGruyereDetails === 'narrated' ||
      d.visits_chateauDeGruyereDetails === 'treasure'
    )
      incr += +140 * d.visits_classNb;
  }
  return incr;
}

export function getVisitsNb(d: FormInputsType): number {
  let incr = 0;
  if (d.visits_maisonDuGruyere) incr += 1;
  if (d.visits_maisonCailler) incr += 1;
  if (d.visits_chateauDeGruyere) incr += 1;
  return incr;
}

export function getCleaningPrice(d: FormInputsType, totalNights: number): number {
  let incr = 0;
  if (d.cleaning && d.typeOfStay !== 'fullBoard') incr += +300;

  if (d.everyDayCleaning && d.typeOfStay !== 'fullBoard')
    incr += +100 * (totalNights - 1 >= 0 ? totalNights - 1 : 0);

  return incr;
}

// Methods

const breakfastPrices = { kids: 7, teens: 7, adults: 8 }; // diner ou souper
const mealPrices = { kids: 13, teens: 14, adults: 16 }; // diner ou souper

const typeOfStayPrices = {
  breakfast: { kids: 0, teens: 0, adults: 0 }, // dej compté dans la nuité | Prix : kids: 7, teens: 7, adults: 8
  autonomous: { kids: 0, teens: 0, adults: 0 },
  fullBoard: {
    kids: mealPrices.kids * 2,
    teens: mealPrices.teens * 2,
    adults: mealPrices.adults * 2,
  }, // dej non-compris
  halfPension: { kids: mealPrices.kids, teens: mealPrices.teens, adults: mealPrices.adults }, // dej non-compris
};

export function getSupplMealsPrice(
  kN: number,
  tN: number,
  aN: number,
  mealAtArrival: boolean,
  mealOnDeparture: boolean,
  goalOfStay: 'school' | 'vacation' | 'weekend' | 'weekstay'
): number {
  let incr = 0;
  let variator = 0;
  if (mealAtArrival && mealOnDeparture) variator += 1;
  if (!mealAtArrival && !mealOnDeparture) variator -= 1;

  if (goalOfStay === 'school') {
    if (kN > tN) {
      incr += mealPrices.kids * (+kN + +tN + +aN);
    } else {
      incr += mealPrices.teens * (+kN + +tN + +aN);
    }
  } else {
    incr += mealPrices.kids * kN;
    incr += mealPrices.teens * tN;
    incr += mealPrices.adults * aN;
  }

  return incr * variator;
}

export function getTypeOfStayPrice(d: FormInputsType, totalNights: number): number {
  let incr = 0;
  // ### Calcul du prix de la pension : ###
  if (d.goalOfStay === 'school') {
    // S'il y a plus d'enfants, considéré comme école primaire -> tarif enfant pour tout le monde
    if (d.kidsNb > d.teensNb) {
      incr +=
        typeOfStayPrices[d.typeOfStay].kids * totalNights * (+d.kidsNb + +d.teensNb + +d.adultsNb);
    } else {
      incr +=
        typeOfStayPrices[d.typeOfStay].teens * totalNights * (+d.kidsNb + +d.teensNb + +d.adultsNb);
    }
  } else {
    incr += typeOfStayPrices[d.typeOfStay].kids * totalNights * d.kidsNb;
    incr += typeOfStayPrices[d.typeOfStay].teens * totalNights * d.teensNb;
    incr += typeOfStayPrices[d.typeOfStay].adults * totalNights * d.adultsNb;
  }

  // Step 4 : additional Meals

  incr += getSupplMealsPrice(
    d.kidsNb,
    d.teensNb,
    d.adultsNb,
    d.mealAtArrival,
    d.mealOnDeparture,
    d.goalOfStay
  );

  return incr;
}

export function getMinNuites(
  goalOfStay: 'school' | 'vacation' | 'weekend' | 'weekstay',
  season: 'summer' | 'autumn' | 'winter' | 'easter' | 'february' | null,
  totalNights: number,
  totalPeople: number
): number {
  // Détection de la période de l'année / définition du nombre de nuitées min. selon le type de clients.
  let minNuites = 160;

  if (goalOfStay === 'weekend') minNuites = 0;

  if (goalOfStay === 'school' && totalNights * totalPeople <= 160) {
    minNuites = 160;
  }

  if (goalOfStay === 'vacation' || goalOfStay === 'weekstay') {
    if (season === 'summer') minNuites = 630;
    if (season === 'autumn' || season === 'winter') minNuites = 420;
    if (season === 'easter' || season === 'february') minNuites = 350;
    // pas de valeur par défaut ??
  }

  if (goalOfStay === 'weekstay' && season === null) {
    const minPeople = totalNights >= 3 ? 60 : 80;

    minNuites = totalPeople > minPeople ? totalPeople * totalNights : minPeople * totalNights;
  }
  // Retourne soit totalNights, si le minimum par nuit est atteint, soit le minimum prédéfini.
  return totalNights * totalPeople > minNuites ? totalNights * totalPeople : minNuites;
}

function getPricePerNight(d: FormInputsType, totalNights: number): number {
  const pricePP = d.goalOfStay === 'weekstay' ? 16 : 14;
  let pricePN = 1200;

  if (getMinNuites(d.goalOfStay, d.season, totalNights, getTotalPeople(d)) > 40 * totalNights) {
    pricePN +=
      Math.ceil(
        (getMinNuites(d.goalOfStay, d.season, totalNights, getTotalPeople(d)) - 40 * totalNights) /
          totalNights
      ) * pricePP;
  }

  if (d.typeOfStay === 'autonomous') return pricePN;

  // Ajout au prix de l'hébergement le prix du déjeuner
  let breakfastPrice = 0; // Total des déjeuners, par jour.

  if (
    getMinNuites(d.goalOfStay, d.season, totalNights, getTotalPeople(d)) / totalNights >
    getTotalPeople(d)
  ) {
    // Si trop peu de monde -> méthode "forfaitaire", 40 personnes / nuit

    // Min persone per nights :
    const minPeoplePerNight = Math.ceil(
      getMinNuites(
        d.goalOfStay,
        d.season,
        DateDifference(d.arrivalDate, d.departureDate),
        +d.kidsNb + +d.teensNb + +d.adultsNb
      ) / DateDifference(d.arrivalDate, d.departureDate)
    );

    // DEV : dynamic breakfast price
    breakfastPrice += minPeoplePerNight * 7;
  } else {
    console.log('Enough people');
    if (d.goalOfStay === 'school') {
      // Enough nights, school
      breakfastPrice +=
        +d.kidsNb + +d.teensNb > +d.adultsNb
          ? breakfastPrices.kids * getTotalPeople(d) // Prix similaire pour ado & enfant, pour le dej.
          : breakfastPrices.adults * getTotalPeople(d); // S'il y a plus d'adultes, il s'agit d'un camp +16, donc on facture plus.
    } else {
      // Enough nights, not autonomous, not school :
      breakfastPrice += breakfastPrices.kids * +d.kidsNb;
      breakfastPrice += breakfastPrices.teens * +d.teensNb;
      breakfastPrice += breakfastPrices.adults * +d.adultsNb;
    }
  }

  return pricePN + breakfastPrice;
}

export function getTimePrice(d: FormInputsType): number {
  let incr = 0;
  if (d.goalOfStay === 'weekend') {
    if (d.arrivalTime === 'early') incr += 100;
    if (d.departureTime === 'late') incr += 100;
  }

  return incr;
}

export function getNightsPrice(d: FormInputsType, totalNights: number): number {
  let incr = 0;
  incr += getPricePerNight(d, totalNights) * totalNights;
  incr += getTimePrice(d);
  return incr;
}

export function getWESupplementsPrice(d: FormInputsType, totalNights: number): number {
  let incr = 0;

  if (d.forfait !== 'xxl') {
    incr += d.addDorms * 200 * totalNights;
    incr += d.addRooms * 50 * totalNights;

    if (d.thirdFloor) incr += +200 * totalNights;
  }

  if (d.discoMaterial) incr += +100; // forfait, pas de tarif par nuit

  if (d.earlyAccess) {
    incr += +300;
    incr += d.earlyNights * 30;
  }

  return incr;
}

export function getWEPrice(d: FormInputsType, totalNights: number): number {
  let incr = 0;
  if (totalNights === 1) {
    incr += d.forfait === 'xxl' ? +2600 : +1800;
  } else {
    incr += d.forfait === 'xxl' ? +3900 : +2700;
  }

  return incr;
}

export function getAccessChargesPrice(d: FormInputsType, totalNights: number): number {
  let incr = 0;
  switch (d.heating) {
    case 'summer':
      incr += 160 * totalNights;
      break;

    case 'winter':
      incr += 280 * totalNights;
      break;

    default:
      // spring | autumn
      incr += 220 * totalNights;
      break;
  }
  return incr;
}

export function getTaxPrice(d: FormInputsType, totalNights: number) {
  return d.adultsNb * 3 * totalNights;
}

export function getMaterialPrice(d: FormInputsType): number {
  let incr = 0;
  // Si matériel mais pas d'animation.
  if (d.introMaterial && !d.introAnimations && d.activityPack === null) {
    incr += d.discoMat ? 100 : 0;
    incr += d.cinemaMat ? 100 : 0;
    incr += d.sportsMat ? 250 : 0;
  }
  return incr;
}

const packPrices = {
  s: 8,
  m: 15,
  l: 20,
  classique: 52,
};

const packMtbPrices = {
  s: packPrices.s + 7,
  m: packPrices.m + 7,
  l: packPrices.l + 7,
  classique: 52,
};

export function getActivitiesPrice(d: FormInputsType): number {
  if (d.mtbs && d.activityPack !== null)
    return packMtbPrices[d.activityPack] * (+d.kidsNb + +d.teensNb);

  if (d.activityPack !== null) return packPrices[d.activityPack] * (+d.kidsNb + +d.teensNb);

  return 0;
}

export function getMtbsPrice(d: FormInputsType): number {
  // Only returns if no pack is selected.
  return d.mtbs ? 0 : d.bikeNb * d.bikeDays * 10;
}

export function getTotalPrice(d: FormInputsType, totalNights: number) {
  let total: number = 0;

  total += +getTaxPrice(d, totalNights);

  // ##### Step 21 #####
  if (d.goalOfStay === 'weekend') {
    total += +getWEPrice(d, totalNights);
    total += +getWESupplementsPrice(d, totalNights);
    total += +getAccessChargesPrice(d, totalNights);
  } else {
    // ##### Step 1 #####
    total += +getTypeOfStayPrice(d, totalNights);
    total += +getNightsPrice(d, totalNights);

    // ##### Step 2 #####
    total += +getMtbsPrice(d);
    total += +getMaterialPrice(d);

    // ##### Step 3 #####
    // Packs
    if (d.activityPack !== null) {
      total += +getActivitiesPrice(d);
    }

    // Animations & Visits
    if (d.activityPack === 'l') {
      total += +getAnimationsPrice(d);
      total += +getVisitsPrice(d);
    }
    if (d.activityPack === 'classique') {
      total += +getVisitsPrice(d);
    }
  }

  // ##### Step 4 #####
  total += getCleaningPrice(d, totalNights);

  return total;
}

function round5Cts(n: number) {
  return Math.round(n / 0.05) * 0.05;
}

export function getPricePP(price: number, people: number): number | null {
  if (price / people !== 0 && people !== 0) return round5Cts(price / people);
  return null;
}
