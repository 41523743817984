import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Hero from '../components/Hero';

export default function Cga() {
  const { t } = useTranslation('', { keyPrefix: 'CGA' });

  return (
    <main id="cga">
      <Hero page="CGA" imgPath="../assets/layouts/team-cover.jpg" panorama="panorama-light-green" />

      {Object.keys(t('sections', { returnObjects: true })).map((sctId) => (
        <section className="container tight" key={`section-${sctId}`}>
          <h3>{t(`sections.${sctId}.title`)}</h3>
          <div className="subsections">
            {Object.keys(t(`sections.${sctId}.subsections`, { returnObjects: true })).map(
              (subsctId) => (
                <div className="spec" key={`subsection-${sctId}-${subsctId}`}>
                  <details>
                    <summary>
                      <strong>
                        {t(`sections.${sctId}.subsections.${subsctId}.subsectionTitle`)}
                      </strong>
                      <span className="detail">{t('details')}</span>
                    </summary>
                    <div className="summary-content">
                      {Object.keys(
                        t(`sections.${sctId}.subsections.${subsctId}.subsectionElts`, {
                          returnObjects: true,
                        })
                      ).map((eltId) => (
                        <p key={`subsectionElt-${sctId}-${subsctId}-${eltId}`}>
                          {t(
                            `sections.${sctId}.subsections.${subsctId}.subsectionElts.${eltId}.label`
                          )}{' '}
                          <Link
                            target="_blank"
                            to={
                              t(
                                `sections.${sctId}.subsections.${subsctId}.subsectionElts.${eltId}.link`
                              ) || '#'
                            }
                          >
                            {t(
                              `sections.${sctId}.subsections.${subsctId}.subsectionElts.${eltId}.textLink`
                            )}
                          </Link>
                        </p>
                      ))}
                    </div>
                  </details>
                </div>
              )
            )}
          </div>
        </section>
      ))}
    </main>
  );
}
