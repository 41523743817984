import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import BooleanInput from '../../input/BooleanInput';
import NumberInput from '../../input/NumberInput';

import { FormInputsType } from '../../input/FormType';
import RadioPriceCard from '../../input/RadioPriceCard';
import { DateDifference } from '../../helpers/DateHelper';

type Step21Type = {
  arrivalDate: string;
  departureDate: string;

  // Step 2.1
  forfait: 'standard' | 'xxl';
  adultsNb: number;
  thirdFloor: boolean;
  discoMaterial: boolean;
  earlyAccess: boolean;
  earlyNights: number;
  addDorms: number;
  addRooms: number;
};

type Step21Props = Step21Type & {
  updateFields: (fields: Partial<FormInputsType>) => void;
  updateThirdFloor: (fields: Partial<FormInputsType>) => void;
};

export default function Step21({
  forfait,
  adultsNb,
  thirdFloor,
  discoMaterial,
  earlyAccess,
  earlyNights,
  addDorms,
  addRooms,
  arrivalDate,
  departureDate,
  updateFields,
  updateThirdFloor,
}: Step21Props) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });

  const allWE: boolean = DateDifference(arrivalDate, departureDate) > 1;

  const cardOptions: {
    value: string;
    title: string;
    subtitle: string;
    price: number;
    body: string | ReactElement;
  }[] = [
    {
      value: 'standard',
      title: t('step21.standard.title'),
      subtitle: t('step21.standard.subtitle'),
      price: allWE ? 2700 : 1800,
      body: (
        <>
          <b>
            {allWE ? t('step21.fromFriday') : t('step21.fromSatday')} {t('step21.toSunday')}
          </b>
          <p>{t('step21.standard.body')}</p>
        </>
      ),
    },
    {
      value: 'xxl',
      title: t('step21.xxl.title'),
      subtitle: t('step21.xxl.subtitle'),
      price: allWE ? 3900 : 2600,
      body: (
        <>
          <b>
            {allWE ? t('step21.fromFriday') : t('step21.fromSatday')} {t('step21.toSunday')}
          </b>
          <p>{t('step21.xxl.body')}</p>
        </>
      ),
    },
  ];

  return (
    <section id="step21" className="configuratorStep container medTight">
      <article>
        <h3 className="centeredDashed">{t('step21.title')}</h3>

        <RadioPriceCard
          pack
          name="forfait"
          title=""
          selectedOption={forfait}
          options={cardOptions}
          updateSingleField={updateFields}
        />

        {forfait === 'standard' && adultsNb > 57 && addRooms < 1 && addDorms < 1 ? (
          <p className="minNightsWarning">
            <i className="fa-solid fa-triangle-exclamation" />
            {t('minNightsWarning.notEnoughBeds')}
          </p>
        ) : (
          ''
        )}

        <div className="tax">
          <b>{t('generalInfos.numbers.tax')}</b>
          <NumberInput
            title={t('step21.taxAdults')}
            name="adultsNb"
            value={adultsNb}
            updateSingleField={updateFields}
          />
          <small className="xs">{t('step21.taxKids')}</small>
        </div>
      </article>

      {forfait !== 'xxl' && (
        <article>
          <h3 className="centeredDashed">{t('step21.addBeds')}</h3>

          <NumberInput
            title={t('step21.addDorms')}
            name="addDorms"
            value={addDorms}
            validation={{
              min: 0,
              max: 4,
            }}
            updateSingleField={updateFields}
          />

          <NumberInput
            title={t('step21.addRooms')}
            name="addRooms"
            value={addRooms}
            validation={{
              min: 0,
              max: 6,
            }}
            updateSingleField={updateFields}
          />

          <a href="../assets/floors/eterpaz-floors.pdf" download>
            {t('step21.downloadFloorPlan')}
          </a>
        </article>
      )}

      <article>
        <h3 className="centeredDashed">{t('step21.3rdFloorTitle')}</h3>
        {forfait !== 'xxl' ? (
          <>
            {!discoMaterial && (
              <BooleanInput
                name="thirdFloor"
                title={t('step21.3rdFloor')}
                initValue={thirdFloor}
                updateSingleField={updateFields}
              />
            )}
            <BooleanInput
              name="discoMaterial"
              title={t('step21.3rdFloorAndDisco')}
              initValue={discoMaterial}
              updateSingleField={updateThirdFloor}
            />
            <small className="xs">{t('step21.3rdFloorSmall')}</small>
          </>
        ) : (
          <BooleanInput
            name="discoMaterial"
            title={t('step21.3rdFloorDiscoOnly')}
            initValue={discoMaterial}
            updateSingleField={updateFields}
          />
        )}
      </article>

      {!allWE && (
        <article>
          <h3 className="centeredDashed">{t('step21.earlyAccess')}</h3>
          <BooleanInput
            name="earlyAccess"
            title={t('step21.earlyAccessLabel')}
            initValue={earlyAccess}
            updateSingleField={updateFields}
          />

          {earlyAccess && (
            <NumberInput
              title={t('step21.earlyNights')}
              name="earlyNights"
              value={earlyNights}
              updateSingleField={updateFields}
            />
          )}
        </article>
      )}
    </section>
  );
}
