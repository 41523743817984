import React from 'react';

interface ActivityProps {
  title: string;
  description: string;
  picto: string;
  imagePath: string;
}

export default function Activity(props: ActivityProps) {
  const { title, description, picto, imagePath } = props;

  return (
    <article className="activity">
      <div className="activityDesc">
        <h3>{title}</h3>
        <p>{description}</p>
        <img src={`./assets/pictograms/${picto}_black.svg`} alt="" />
      </div>
      <div className="activityImg" style={{ backgroundImage: `url(${imagePath})` }} />
    </article>
  );
}
