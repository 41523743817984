import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import LanguageDetector from 'i18next-browser-languagedetector';

import translationsFR from './locales/fr.json';
import translationsEN from './locales/en.json';
import translationsDE from './locales/de.json';

i18n
  //  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    load: 'languageOnly',
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    resources: {
      fr: {
        translation: translationsFR,
      },
      en: {
        translation: translationsEN,
      },
      de: {
        translation: translationsDE,
      },
    },
  });

export default i18n;
