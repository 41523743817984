import React, { ReactNode } from 'react';

interface PackProps {
  children: ReactNode;
  title: string;
  surname: string;
  subtitle: string;
  price: string;
  headerSubtitle: string;
}

export default function Pack({
  title,
  subtitle,
  price,
  children,
  headerSubtitle,
  surname,
}: PackProps) {
  return (
    <div className="pack">
      <div className="packHeader">
        {surname && <h5>{surname}</h5>}

        <h3>{title}</h3>
        {headerSubtitle?.length !== 0 ? <small className="xsmall">{headerSubtitle}</small> : ''}
      </div>
      <div className="packRibbon">
        <span className="packSubtitle">
          <b>{subtitle}</b>
        </span>
        <span className="packPrice">{price}</span>
      </div>
      <div className="packBody">{children}</div>
    </div>
  );
}
