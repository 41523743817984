import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';
import Activity from '../components/Activity';
import Cta from '../components/Cta';
import Hero from '../components/Hero';
import ImgButton from '../components/ImgButton';
import LeafMap from '../components/LeafMap';
import Carousel from '../components/Carousel';

export default function Activities() {
  const { t } = useTranslation('', { keyPrefix: 'Activities' });

  const markers: { id: number; position: [number, number]; imgPath: string }[] = [
    { id: 0, position: [46.595249, 7.099474], imgPath: './assets/pictograms/map/mapHouse.png' },
    {
      id: 1,
      position: [46.600358536380746, 7.095244317656967],
      imgPath: './assets/pictograms/map/mapSports.png',
    },
    {
      id: 2,
      position: [46.60957766461912, 7.109020598160854],
      imgPath: './assets/pictograms/map/mapVisits.png',
    },
    {
      id: 3,
      position: [46.6072069300623, 7.108557800725472],
      imgPath: './assets/pictograms/map/mapVisits.png',
    },
    {
      id: 4,
      position: [46.582142834530195, 7.0731392847876995],
      imgPath: './assets/pictograms/map/mapVisits.png',
    },
    {
      id: 5,
      position: [46.58472383713787, 7.084085641932399],
      imgPath: './assets/pictograms/map/mapVisits.png',
    },
    {
      id: 6,
      position: [46.604957380104494, 7.11390006777392],
      imgPath: './assets/pictograms/map/mapHikes.png',
    },
    {
      id: 7,
      position: [46.61230356194383, 7.132785046757897],
      imgPath: './assets/pictograms/map/mapHikes.png',
    },
    {
      id: 8,
      position: [46.61694914916358, 7.149870993168291],
      imgPath: './assets/pictograms/map/mapHikes.png',
    },
    {
      id: 9,
      position: [46.615404019583075, 7.095859640636941],
      imgPath: './assets/pictograms/map/mapHikes.png',
    },
    {
      id: 10,
      position: [46.60349951905225, 7.09112504951678],
      imgPath: './assets/pictograms/map/mapHikes.png',
    },
    {
      id: 11,
      position: [46.5613501011995, 7.037532906876797],
      imgPath: './assets/pictograms/map/mapMountain.png',
    },
    {
      id: 12,
      position: [46.61819538571667, 7.168395830299386],
      imgPath: './assets/pictograms/map/mapSports.png',
    },
  ];

  return (
    <main id="activities">
      <Hero page="Activities" imgPath="../assets/layouts/activities/activities_hero.webp" full />
      {/* Sous-menu */}
      <section id="activitiesOnSite">
        <div className="fadeToBottomGreen" />
        <div className="fadeToTopWhite" />
        <div className="container">
          <h2>{t('onSite.title')}</h2>
          <h4>{t('onSite.subtitle')}</h4>
          <div className="onSiteActivities">
            {Object.keys(t('onSite.activities', { returnObjects: true })).map((key) => (
              <div className="onSiteActivity" key={key}>
                <div className="icon">
                  <i className={`fa-solid ${t(`onSite.activities.${key}.icon`)}`} />
                </div>
                <div className="texts">
                  <h6>{t(`onSite.activities.${key}.name`)}</h6>
                  <small>{t(`onSite.activities.${key}.desc`)}</small>
                </div>
              </div>
            ))}
          </div>
          <div className="onSiteMaterial">
            <h3>{t('onSiteMaterial.title')}</h3>
            <p>{t('onSiteMaterial.desc')}</p>
            <div className="row">
              <div className="col">
                <h6>{t('onSiteMaterial.sct1.title')}</h6>
                <ul>
                  <li>{t('onSiteMaterial.sct1.desc')}</li>
                  <li>{t('onSiteMaterial.sct1.desc1')}</li>
                  <li>{t('onSiteMaterial.sct1.desc2')}</li>
                  <li>{t('onSiteMaterial.sct1.desc3')}</li>
                </ul>
              </div>
              <div className="col">
                <h6>{t('onSiteMaterial.sct2.title')}</h6>
                <p>{t('onSiteMaterial.sct2.desc')}</p>
                <ul>
                  <li>{t('onSiteMaterial.sct2.desc1')}</li>
                  <li>{t('onSiteMaterial.sct2.desc2')}</li>
                  <li>{t('onSiteMaterial.sct2.desc3')}</li>
                </ul>
                <br />
                <Link
                  to="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G2_Inventaire_MatSportJeux.pdf"
                  target="_blank"
                >
                  {t('onSiteMaterial.btn')}
                </Link>
              </div>
              <div className="col">
                <h6>{t('onSiteMaterial.sct3.title')}</h6>
                <p>{t('onSiteMaterial.sct3.desc')}</p>
                <Link to="/cga-pdf/2_ConditionsEtTarifs/E_Packs/E3_PackM.pdf" target="_blank">
                  {t('onSiteMaterial.btn')}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="activitiesForSchools">
        <div className="container">
          <div className="sectionTitles">
            <h2 className="underlinedTitle">{t('Packs.title')}</h2>
            <p>{t('Packs.desc')}</p>
          </div>
        </div>
        <Carousel localeKey="activities" />
        <ImgButton
          to="/cga"
          label={t('Packs.link')}
          picto="archer_black"
          btnType="btn--secondary"
        />
      </section>

      <section id="activitiesMtb">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 imgCol">
              {/* <img src="../assets/layouts/hero-test-cover.jpg" alt="Notre équipe" /> */}
              <img src="/assets/layouts/activities/mtbs.webp" alt="" />
            </div>
            <div className="col-lg-8 textCol">
              <h2>{t('Packs.mtb.title')}</h2>
              <p>{t('Packs.mtb.desc')}</p>
              <small>{t('Packs.mtb.sizes')}</small>
              <ImgButton
                to="/cga"
                label={t('Packs.link')}
                picto="velo_black"
                btnType="btn--secondary"
              />
            </div>
          </div>
        </div>
      </section>

      <Cta
        tagId="cta"
        to="/configurator"
        label={t('CTA.btn')}
        picto="fa-calendar-days"
        btnType="btn--white"
        title={t('CTA.title')}
        surtitle={t('CTA.surtitle')}
      />

      <section id="activitiesAnimations" className="container">
        <div className="dashedBox">
          <div className="sectionTitle">
            <h2>{t('Animations.title')}</h2>
            <h4>{t('Animations.surtitle')}</h4>
            <p>{t('Animations.desc')}</p>
          </div>

          {Object.keys(t('Animations.animations', { returnObjects: true })).map((key) => (
            <Activity
              key={key}
              title={t(`Animations.animations.${key}.title`)}
              description={t(`Animations.animations.${key}.desc`)}
              picto={t(`Animations.animations.${key}.pictogram`)}
              imagePath={t(`Animations.animations.${key}.img`)}
            />
          ))}

          <ImgButton
            to="/team"
            label="Découvrir nos animateurs"
            picto="sapin_white"
            btnType="btn--primary"
          />
        </div>
      </section>

      <section id="activitiesMap" className="container">
        <h2>{t('InTheArea.title')}</h2>
        <LeafMap center={[46.595249, 7.099474]} markers={markers} />
      </section>
    </main>
  );
}
