import React, { useLayoutEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainMenu from './components/MainMenu';
import Footer from './components/Footer';
import Error404 from './pages/Error404';
import Home from './pages/Home';
import Team from './pages/Team';
import Activities from './pages/Activities';
import Camp from './pages/Camp';
import Cga from './pages/Cga';
import Configurator from './pages/configurator/Configurator';
import Lpd from './pages/LPD';
import Legal from './pages/Legal';

export default function App() {
  const { t } = useTranslation('', { keyPrefix: 'PageTitles' });

  const location = useLocation();

  useLayoutEffect(() => {
    window.scrollTo(0, 0);
    document.title = `Colonie Les Eterpaz - ${t('home')} à Broc`;
    if (location.pathname === '/activities')
      document.title = `Colonie Les Eterpaz - ${t('activities')}`;
    if (location.pathname === '/team') document.title = `Colonie Les Eterpaz - ${t('team')}`;
    if (location.pathname === '/camp') document.title = `Colonie Les Eterpaz - ${t('camp')}`;
    if (location.pathname === '/configurator')
      document.title = `Colonie Les Eterpaz - ${t('configurator')}`;
    if (location.pathname === '/cga') document.title = `Colonie Les Eterpaz - ${t('cga')}`;
    if (location.pathname === '/protection')
      document.title = `Colonie Les Eterpaz - ${t('protection')}`;
    if (location.pathname === '/legal') document.title = `Colonie Les Eterpaz - ${t('legal')}`;
  }, [location.pathname]);

  return (
    <div className="App">
      <MainMenu />

      <Routes>
        <Route index path="/" element={<Home />} />
        <Route path="/activities" element={<Activities />} />
        <Route path="/team" element={<Team />} />
        <Route path="/camp" element={<Camp />} />
        {/* <Route path="/configurator" element={<ConfigInProgress />} /> */}
        <Route path="/configurator" element={<Configurator />} />
        <Route path="/cga" element={<Cga />} />
        <Route path="/protection" element={<Lpd />} />
        <Route path="/legal" element={<Legal />} />
        <Route path="/*" element={<Error404 />} />

        {/* <Route path="/mail" element={<MailTemplate />} /> */}
      </Routes>

      <Footer contactFormUrl="/configurator" />
    </div>
  );
}
