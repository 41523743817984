import React from 'react';

type SelectInputType = {
  title: string;
  name: string;
  options: { name: string; title: string }[];
  value: string;
  disabled?: boolean;
  addClass?: string;
};

type SelectInputProps = SelectInputType & {
  updateSingleField: (fields: Partial<SelectInputType>) => void;
};

export default function SelectInput({
  title,
  name,
  options,
  addClass,
  value,
  disabled,
  updateSingleField,
}: SelectInputProps) {
  return (
    <div className={`input input__select ${addClass}`}>
      <label htmlFor={name}>
        {title}
        <select
          name={name}
          onChange={(e) => updateSingleField({ [name]: e.target.value })}
          disabled={disabled}
          value={value}
        >
          {options.map((option) => (
            <option key={option.name} value={option.name} selected={value === option.name}>
              {option.title}
            </option>
          ))}
        </select>
      </label>
      {/* <small>{error || select.info}</small> */}
    </div>
  );
}
