import React from 'react';
import { Link } from 'react-router-dom';

interface ImgButtonProps {
  to: string;
  label: string;
  picto:
    | 'archer_black'
    | 'archer_white'
    | 'maison_black'
    | 'maison_white'
    | 'montagne_black'
    | 'montagne_white'
    | 'sapin_black'
    | 'sapin_white'
    | 'velo_black'
    | 'velo_white';
  btnType: string;
  target?: boolean;
}

/**
 * Custom btn
 * @param picto - Pictograms list : archer  | maison | montagne | sapin | velo + _black | _white

 * @returns 
 */
export default function ImgButton(props: ImgButtonProps) {
  const { to, label, picto, btnType, target } = props;

  return (
    <Link
      to={to}
      className={`btn ${btnType} btn--img`}
      target={target ? '_blank' : ''}
      rel={target ? 'noreferrer' : ''}
    >
      <img src={`./assets/pictograms/${picto}.svg`} alt="" />
      {label}
    </Link>
  );
}
