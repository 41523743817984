import React from 'react';
import { useTranslation } from 'react-i18next';
import BooleanInput from '../../input/BooleanInput';

import { FormInputsType } from '../../input/FormType';
import { getSupplMealsPrice } from '../../helpers/PriceHelper';

type Step4Type = {
  kidsNb: number;
  teensNb: number;
  adultsNb: number;
  goalOfStay: 'school' | 'vacation' | 'weekend' | 'weekstay';
  typeOfStay: 'autonomous' | 'breakfast' | 'halfPension' | 'fullBoard';
  // Step 4
  mealAtArrival: boolean;
  mealOnDeparture: boolean;
  cleaning: boolean;
  everyDayCleaning: boolean;
};

type Step4Props = Step4Type & {
  updateFields: (fields: Partial<FormInputsType>) => void;
};

export default function Step4({
  kidsNb,
  teensNb,
  adultsNb,
  goalOfStay,
  typeOfStay,
  mealAtArrival,
  mealOnDeparture,
  cleaning,
  everyDayCleaning,
  updateFields,
}: Step4Props) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });

  const chf = new Intl.NumberFormat('en-CH', {
    style: 'currency',
    currency: 'CHF',
  });

  return (
    <section className="configuratorStep container medTight">
      {typeOfStay === 'fullBoard' && (
        <article>
          <h3 className="centeredDashed">{t('step4.addMealsTitle')}</h3>
          <p>{t('step4.addMealsDesc')}</p>

          <BooleanInput
            name="mealAtArrival"
            title={t('step4.addMealsLabel1')}
            initValue={mealAtArrival}
            updateSingleField={updateFields}
          />

          <BooleanInput
            name="mealOnDeparture"
            title={t('step4.addMealsLabel2')}
            initValue={mealOnDeparture}
            updateSingleField={updateFields}
          />

          {!mealOnDeparture && !mealAtArrival ? (
            <small>
              {t('step4.addMealsDiscount')}{' '}
              {chf.format(
                getSupplMealsPrice(
                  kidsNb,
                  teensNb,
                  adultsNb,
                  mealAtArrival,
                  mealOnDeparture,
                  goalOfStay
                )
              )}
            </small>
          ) : (
            ''
          )}
          {mealOnDeparture && mealAtArrival ? (
            <small>
              {t('step4.addMealsExtra')}{' '}
              {chf.format(
                getSupplMealsPrice(
                  kidsNb,
                  teensNb,
                  adultsNb,
                  mealAtArrival,
                  mealOnDeparture,
                  goalOfStay
                )
              )}
            </small>
          ) : (
            ''
          )}
        </article>
      )}

      <article>
        <h3 className="centeredDashed">{t('step4.cleaning')}</h3>
        {typeOfStay === 'fullBoard' ? (
          t('step4.cleaningFullBoard')
        ) : (
          <>
            <p>{goalOfStay === 'weekend' ? t('step4.cleaningWE') : t('step4.cleaningDesc')}</p>

            <BooleanInput
              name="cleaning"
              title={t('step4.cleaningLabel1')}
              initValue={cleaning}
              updateSingleField={updateFields}
            />

            {goalOfStay !== 'weekend' && (
              <BooleanInput
                name="everyDayCleaning"
                title={t('step4.cleaningLabel2')}
                initValue={everyDayCleaning}
                updateSingleField={updateFields}
              />
            )}

            <a
              href="/cga-pdf/1_ConditionsGeneralesDHebergement/D_Nettoyages/D1_CeT_Nettoyages.pdf"
              target="_blank"
            >
              {t('step4.cleaningConditions')}
            </a>
          </>
        )}
      </article>
    </section>
  );
}
