import React from 'react';
import { useTranslation } from 'react-i18next';
import BooleanInput from '../../input/BooleanInput';
import RadioInput from '../../input/RadioInput';
import SelectInput from '../../input/SelectInput';
import TextInput from '../../input/TextInput';
import { DateDifference, Today, getTimeOptionsData, isWeekEnd } from '../../helpers/DateHelper';

import { FormInputsType } from '../../input/FormType';
import NumberInput from '../../input/NumberInput';
import { getMinNuites } from '../../helpers/PriceHelper';

type GeneralInfosType = {
  name: string;
  phone: string;
  mail: string;
  title: string;
  address: string;
  nip: number | undefined;
  location: string;
  country: string;
  company: string;
  kidsNb: number;
  teensNb: number;
  adultsNb: number;
  goalOfStay: 'school' | 'vacation' | 'weekend' | 'weekstay';
  fridayArrival: boolean;

  // stayInfos
  typeOfStay: 'autonomous' | 'breakfast' | 'halfPension' | 'fullBoard';
  arrivalDate: string;
  arrivalTime: 'normal' | 'early' | 'late';
  departureDate: string;
  departureTime: 'normal' | 'early' | 'late';
  season: 'summer' | 'autumn' | 'winter' | 'easter' | 'february' | null;

  // Intro
  introMaterial: boolean;
  introAnimations: boolean;
  introCleaning: boolean;
};

type GeneralInfosFormProps = GeneralInfosType & {
  updateFields: (fields: Partial<FormInputsType>) => void;
  updatePeople: (fields: Partial<FormInputsType>) => void;
  updateIntro: (fields: Partial<FormInputsType>) => void;
  updateGoalOfStay: (fields: Partial<FormInputsType>) => void;
  updateDates: (name: string, e: React.ChangeEvent<HTMLInputElement>) => void;
  totalNights: number;
};

export default function Step1({
  name,
  phone,
  mail,
  title,
  address,
  nip,
  location,
  country,
  company,
  kidsNb,
  goalOfStay,
  typeOfStay,
  fridayArrival,
  teensNb,
  adultsNb,
  arrivalDate,
  arrivalTime,
  departureDate,
  departureTime,
  season,
  introMaterial,
  introAnimations,
  introCleaning,

  updateFields,
  updateDates,
  updatePeople,
  updateIntro,
  updateGoalOfStay,

  totalNights,
}: GeneralInfosFormProps) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });

  const titleOptions = [
    {
      name: '-',
      title: '-',
    },
    {
      name: 'M.',
      title: t('generalInfos.nameTitleM'),
    },
    {
      name: 'Mme.',
      title: t('generalInfos.nameTitleF'),
    },
  ];

  const typeOfStayOptions = [
    {
      value: 'fullBoard',
      title: t('generalInfos.typeOfStay.fullBoard'),
      locked: goalOfStay === 'weekend',
    },
    {
      value: 'halfPension',
      title: t('generalInfos.typeOfStay.halfPension'),
      locked: goalOfStay === 'weekend',
    },
    {
      value: 'breakfast',
      title: t('generalInfos.typeOfStay.breakfast'),
      locked: goalOfStay === 'weekend',
    },
    { value: 'autonomous', title: t('generalInfos.typeOfStay.autonomous'), locked: false },
  ];

  const typeOfVisitorOptions = [
    { name: 'school', title: t('generalInfos.typeOfVisitor.school') },
    { name: 'vacation', title: t('generalInfos.typeOfVisitor.vacation') },
    {
      name: 'weekend',
      title: t('generalInfos.typeOfVisitor.weekend'),
    },
    {
      name: 'weekstay',
      title: t('generalInfos.typeOfVisitor.weekstay'),
    },
  ];

  const timeOptionsTable = getTimeOptionsData();

  const getTimeOptions = (
    goal: 'school' | 'vacation' | 'weekend' | 'weekstay',
    coming: 'arrival' | 'departure'
  ) => {
    switch (goal) {
      case 'vacation':
        return (
          <SelectInput
            title={coming === 'arrival' ? t('stayInfos.arrivalTime') : t('stayInfos.departureTime')}
            name={coming === 'arrival' ? 'arrivalTime' : 'departureTime'}
            addClass="colSelect half-col"
            options={timeOptionsTable[1][coming]}
            value={coming === 'arrival' ? arrivalTime : departureTime}
            updateSingleField={updateFields}
          />
        );

      case 'weekend':
        return (
          <SelectInput
            title={coming === 'arrival' ? t('stayInfos.arrivalTime') : t('stayInfos.departureTime')}
            name={coming === 'arrival' ? 'arrivalTime' : 'departureTime'}
            addClass="colSelect half-col"
            options={timeOptionsTable[fridayArrival ? 2 : 3][coming]}
            value={coming === 'arrival' ? arrivalTime : departureTime}
            updateSingleField={updateFields}
          />
        );

      default:
        return (
          <SelectInput
            title={coming === 'arrival' ? t('stayInfos.arrivalTime') : t('stayInfos.departureTime')}
            name={coming === 'arrival' ? 'arrivalTime' : 'departureTime'}
            addClass="colSelect half-col"
            options={timeOptionsTable[0][coming]}
            value={coming === 'arrival' ? arrivalTime : departureTime}
            updateSingleField={updateFields}
          />
        );
    }
  };

  return (
    <section className="configuratorStep container">
      <h2>{t('intro.title')}</h2>
      <h4>{t(`intro.subtitle`)}</h4>
      <p>{t('intro.desc')}</p>

      <h3 className="centeredDashed">{t('generalInfos.title')}</h3>
      <article className="inputsGroup tight">
        <SelectInput
          title={t('generalInfos.nameTitle')}
          name="title"
          addClass="px100-col colSelect"
          options={titleOptions}
          value={title}
          updateSingleField={updateFields}
        />

        <TextInput
          title={t('generalInfos.name')}
          name="name"
          addClass="rpx100-col"
          validation={{ minLength: 2, maxLength: 50 }}
          updateSingleField={updateFields}
          value={name}
          required
        />

        <TextInput
          title={t('generalInfos.company')}
          name="company"
          addClass="half-col"
          validation={{ minLength: 2, maxLength: 50 }}
          updateSingleField={updateFields}
          value={company}
          required
        />
        <TextInput
          title={t('generalInfos.mail')}
          name="mail"
          addClass="half-col"
          info={t('generalInfos.mailPlaceholder') || ''}
          validation={{ regex: '[^@ \t\r\n]+@[^@ \t\r\n]+.[^@ \t\r\n]+' }}
          updateSingleField={updateFields}
          value={mail}
          required
        />
        <TextInput
          title={t('generalInfos.phone')}
          name="phone"
          addClass="half-col"
          info={t('generalInfos.phonePlaceholder') || ''}
          validation={{ regex: '[0]{1,2}[1-9][0-9]{8,13}', minLength: 10 }}
          updateSingleField={updateFields}
          value={phone}
        />

        <TextInput
          title={t('generalInfos.country')}
          name="country"
          addClass="half-col"
          validation={{ minLength: 1, maxLength: 50 }}
          updateSingleField={updateFields}
          value={country}
        />

        <TextInput
          title={t('generalInfos.address')}
          name="address"
          validation={{ minLength: 2, maxLength: 100 }}
          updateSingleField={updateFields}
          value={address}
        />

        <NumberInput
          title={t('generalInfos.nip')}
          name="nip"
          value={nip}
          addClass="persInfos px100-col"
          validation={{ min: 0, max: 9999, maxLength: 4 }}
          updateSingleField={updateFields}
        />

        <TextInput
          title={t('generalInfos.location')}
          name="location"
          addClass="rpx100-col"
          validation={{ minLength: 2, maxLength: 50 }}
          updateSingleField={updateFields}
          value={location}
        />

        <SelectInput
          title={t('generalInfos.typeOfVisitor.name')}
          name="goalOfStay"
          options={typeOfVisitorOptions}
          value={goalOfStay}
          updateSingleField={updateGoalOfStay}
        />
        <small>{t(`generalInfos.typeOfVisitorDesc.${goalOfStay}`)}</small>
      </article>
      <article className="inputsGroup tight" id="peopleNb">
        <b>{t('generalInfos.numbers.name')}</b>
        <NumberInput
          title={t('generalInfos.numbers.kids')}
          name="kidsNb"
          value={kidsNb}
          validation={{ min: 0, max: 170 }}
          updateSingleField={updatePeople}
        />

        <NumberInput
          title={t('generalInfos.numbers.teens')}
          name="teensNb"
          value={teensNb}
          validation={{ min: 0, max: 170 }}
          updateSingleField={updatePeople}
        />

        <NumberInput
          title={t('generalInfos.numbers.adults')}
          name="adultsNb"
          value={adultsNb}
          validation={{ min: 0, max: 170 }}
          updateSingleField={updatePeople}
        />

        <small>{t('generalInfos.numbers.tax')}</small>

        {(goalOfStay === 'school' || goalOfStay === 'vacation') &&
        kidsNb < 1 &&
        teensNb < 1 &&
        adultsNb > 0 ? (
          <p className="minNightsWarning">
            <i className="fa-solid fa-triangle-exclamation" />
            {t(`minNightsWarning.noKids1`)} {t(`generalInfos.typeOfVisitor.${goalOfStay}`)}
            {t(`minNightsWarning.noKids2`)}
          </p>
        ) : (
          ''
        )}
      </article>

      <h3 className="centeredDashed">{t('stayInfos.title')}</h3>

      <article className="inputsGroup tight">
        <RadioInput
          title={t(`generalInfos.typeOfStay.name`)}
          name="typeOfStay"
          selectedOption={typeOfStay}
          options={typeOfStayOptions}
          updateSingleField={updateFields}
        />

        {goalOfStay === 'weekend' && <small>{t(`generalInfos.typeOfStay.warningWE`)}</small>}

        <div className="input input__select colSelect half-col">
          <label htmlFor="arrivalDate">
            {t('stayInfos.arrivalDate')}
            <input
              type="date"
              name="arrivalDate"
              id="arrivalDate"
              min={Today()}
              value={arrivalDate}
              onChange={(e) => updateDates('arrivalDate', e)}
            />
          </label>
        </div>

        <div className="input input__select colSelect half-col">
          <label htmlFor="departureDate">
            {t('stayInfos.departureDate')}
            <input
              type="date"
              value={departureDate}
              name="departureDate"
              id="departureDate"
              min={arrivalDate}
              onChange={(e) => updateDates('departureDate', e)}
            />
          </label>
        </div>

        {DateDifference(arrivalDate, departureDate) === 0 && (
          <p className="minNightsWarning">
            <i className="fa-solid fa-triangle-exclamation" />
            {t('minNightsWarning.noNight')}
          </p>
        )}

        {getMinNuites(
          goalOfStay,
          season,
          DateDifference(arrivalDate, departureDate),
          +kidsNb + +teensNb + +adultsNb
        ) > totalNights && DateDifference(arrivalDate, departureDate) > 0 ? (
          <p className="minNightsWarning">
            <i className="fa-solid fa-triangle-exclamation" />
            {t('minNightsWarning.notEnoughNightsIntro1', {
              goalOfStay: t(`generalInfos.typeOfVisitor.${goalOfStay}`),
            })}
            {t(`seasons.${season}`)
              ? t('minNightsWarning.notEnoughNightsIntro2', {
                  season: t(`seasons.${season}`),
                })
              : ''}
            <b>
              {t('minNightsWarning.notEnoughNights1', {
                minNights: getMinNuites(
                  goalOfStay,
                  season,
                  DateDifference(arrivalDate, departureDate),
                  +kidsNb + +teensNb + +adultsNb
                ),
              })}
            </b>
            {t('minNightsWarning.notEnoughNights2', {
              minNightsForDuration: Math.ceil(
                getMinNuites(
                  goalOfStay,
                  season,
                  DateDifference(arrivalDate, departureDate),
                  +kidsNb + +teensNb + +adultsNb
                ) / DateDifference(arrivalDate, departureDate)
              ),
              stayDuration: DateDifference(arrivalDate, departureDate),
              minPeople: Math.ceil(
                getMinNuites(
                  goalOfStay,
                  season,
                  DateDifference(arrivalDate, departureDate),
                  +kidsNb + +teensNb + +adultsNb
                ) / DateDifference(arrivalDate, departureDate)
              ),
            })}
          </p>
        ) : (
          ''
        )}

        {goalOfStay === 'weekend' && !isWeekEnd(arrivalDate, departureDate) ? (
          <p className="minNightsWarning">
            <i className="fa-solid fa-triangle-exclamation" />
            {t('minNightsWarning.noWE')}
          </p>
        ) : (
          ''
        )}

        {getTimeOptions(goalOfStay, 'arrival')}
        {getTimeOptions(goalOfStay, 'departure')}

        <iframe
          src="https://www.groups.swiss/{{lang}}/leisure/Les-Eterpaz-Broc-842/widget"
          title="Aperçu des disponibilités de la Colonie Les Eterpaz"
          id="groupsCalendar"
        />
      </article>

      <h3 className="centeredDashed">{t('prestationsIntro.title')}</h3>
      <p>{t('prestationsIntro.desc')}</p>

      <article className="inputsGroup medTight">
        <BooleanInput
          title={t('prestationsIntro.prest1')}
          name="introMaterial"
          subtitle={t('prestationsIntro.subtitle1') || ''}
          initValue={introMaterial}
          updateSingleField={updateIntro}
        />
        <BooleanInput
          name="introAnimations"
          title={t('prestationsIntro.prest2')}
          subtitle={t('prestationsIntro.subtitle2') || ''}
          initValue={introAnimations}
          twoLined
          title2={t('prestationsIntro.prest2-1') || ''}
          subtitle2={t('prestationsIntro.subtitle2-1') || ''}
          updateSingleField={updateIntro}
        />
        <BooleanInput
          name="introCleaning"
          title={t('prestationsIntro.prest3')}
          subtitle={t('prestationsIntro.subtitle3') || ''}
          initValue={introCleaning}
          updateSingleField={updateIntro}
        />
      </article>
    </section>
  );
}
