import React from 'react';
import { useTranslation } from 'react-i18next';
import ImgButton from '../components/ImgButton';
import Panorama from '../components/Panorama';

export default function Home() {
  const { t } = useTranslation('', { keyPrefix: 'Home' });
  return (
    <main id="home">
      <div
        id="homeHero"
        className="hero hero--full"
        style={{ backgroundImage: `url(../assets/layouts/home/home-cover.webp)` }}
      >
        <video className="videoBackground" autoPlay muted loop aria-hidden>
          <source src="/assets/videos/home.mp4" type="video/mp4" />
        </video>
        <div className="hero__content">
          <img
            src="/assets/logos/White+Colors_Thick_Centered.svg"
            alt=""
            className="d-none d-md-block"
          />
          <img src="/assets/logos/White+Colors_Thick.svg" alt="" className="d-md-none d-block" />
          <h1>{t('subtitle')}</h1>
        </div>

        <Panorama img="panorama-white" />
      </div>
      <section id="home__summary" className="container">
        {/* 
        <div
          className="imgCol"
          style={{ backgroundImage: 'url(../assets/layouts/home/home_summary.jpg)' }}
        />
  */}
        <div className="sectionTitles">
          <h4>{t('summary.surtitle')}</h4>
          <h2>{t('summary.title')}</h2>
        </div>
        <p>{t('summary.description')}</p>
        <div className="pictograms">
          {Object.keys(t('icons', { returnObjects: true })).map((icon) => (
            <div className="pictoBox" key={icon}>
              <img src={`./assets/pictograms/${t(`icons.${icon}.icon`)}.svg`} alt="" />
              {/* <i className={`fa-solid ${t(`icons.${icon}.icon`)}`} /> */}
              <p>{t(`icons.${icon}.label`)}</p>
            </div>
          ))}
        </div>
        <div className="videoHolder">
          <iframe
            className="ytVideo"
            src="https://www.youtube.com/embed/HK7adVV2izY?controls=0"
            title="YouTube video player"
          />
        </div>
        <ImgButton to="/camp" label="En savoir plus" btnType="btn--primary" picto="maison_white" />
      </section>
      <section id="home__activitiesCTA">
        <div className="CtaContent">
          <h2>{t('activities.title')}</h2>
          <p>{t('activities.description')}</p>
          <ImgButton
            to="/activities"
            label={t('activities.btn')}
            btnType="btn--secondary"
            picto="maison_black"
          />
        </div>
      </section>
      <section id="home__ourCamps" className="container">
        <h2 className="underlinedTitle">{t('camps.schoolsTitle')}</h2>
        <div className="camps">
          {Object.keys(t('camps.schools', { returnObjects: true })).map((key) => (
            <article className="camp" key={`schools${key}`}>
              <div
                className="card-top"
                style={{ backgroundImage: `url(${t(`camps.schools.${key}.img`)})` }}
              />
              <div className="card-bottom">
                <h3>{t(`camps.schools.${key}.title`)}</h3>
                <p>{t(`camps.schools.${key}.desc`)}</p>
              </div>
            </article>
          ))}
        </div>

        <h2 className="underlinedTitle">{t('camps.groupsTitle')}</h2>
        <div className="camps">
          {Object.keys(t('camps.groups', { returnObjects: true })).map((key) => (
            <article className="camp" key={`groups${key}`}>
              <div
                className="card-top"
                style={{ backgroundImage: `url(${t(`camps.groups.${key}.img`)})` }}
              />
              <div className="card-bottom">
                <h3>{t(`camps.groups.${key}.title`)}</h3>
                <p>{t(`camps.groups.${key}.desc`)}</p>
              </div>
            </article>
          ))}
        </div>
      </section>
    </main>
  );
}
