import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BooleanInput from '../../input/BooleanInput';

import { FormInputsType } from '../../input/FormType';
import CheckboxInput from '../../input/CheckboxInput';
import RadioPriceCard from '../../input/RadioPriceCard';
import NumberInput from '../../input/NumberInput';

type CampPrestationsType = {
  // Step 2
  mtbs: boolean;
  discoMat: boolean;
  cinemaMat: boolean;
  sportsMat: boolean;
  bikeNb: number;
  bikeDays: number;
  activityPack: 's' | 'm' | 'l' | 'classique' | null;
};

type CampPrestationsFormProps = CampPrestationsType & {
  updateFields: (fields: Partial<FormInputsType>) => void;
};

export default function Step2({
  mtbs,
  discoMat,
  cinemaMat,
  sportsMat,
  bikeDays,
  bikeNb,
  activityPack,
  updateFields,
}: CampPrestationsFormProps) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });
  const tPacks = useTranslation('', { keyPrefix: 'Packs' }).t;

  const [customMat, setCustomMat] = useState<boolean>(activityPack === null);

  const switchingToCustomMat = () => {
    window.scrollTo(0, 0);
    setCustomMat(!customMat);

    // Mettre à jour les valeurs des champs
    updateFields({
      activityPack: customMat ? 's' : null,
      mtbs: customMat,
      discoMat: customMat,
      cinemaMat: customMat,
      sportsMat: customMat,
      bikeNb: 0,
      bikeDays: 0,
    });
  };

  const upgradeToPack = () => {
    window.scrollTo(0, 0);
    // Upgrading from "Material Only" to the other packs (unilateral switch)
    updateFields({ introAnimations: true, activityPack: 'l' });
  };

  const cardOptions: {
    value: string;
    surtitle?: string;
    title: string;
    subtitle: string;
    price: number;
    priceText?: string;
    body: string | ReactElement;
  }[] = [
    {
      value: 's',
      surtitle: `${tPacks('activities.0.surname')}${mtbs ? '+' : ''}`,
      title: tPacks('activities.0.name'),
      subtitle: t('packs.s.subtitle'),
      priceText: `${t('packs.s.priceText')}${mtbs ? `, ${t('mtbs.withMtb')}` : ''}`,
      price: mtbs ? 15 : 8,
      body: (
        <>
          <b>{t('packs.s.s1title')}</b>
          <p>{t('packs.s.s1desc')}</p>

          <a
            href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G2_Inventaire_MatSportJeux.pdf"
            target="_blank"
          >
            {t('inventory')}
          </a>
          <hr />
          <b>{t('packs.s.s2title')}</b>
          <p>{t('packs.s.s2desc')}</p>
          <a
            href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G3_Inventaire_MatDisco.pdf"
            target="_blank"
          >
            {t('inventory')}
          </a>
          <hr />
          <b>{t('packs.s.s3title')}</b>
          <p>{t('packs.s.s3desc')}</p>
          <a
            href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G4_Inventaire_MatCinema.pdf"
            target="_blank"
          >
            {t('inventory')}
          </a>
          <hr />
          <span className={`${mtbs || 'notAvailable'}`}>
            <b>{t('packs.s.s4title')}</b>
            <p>{t('packs.s.s4desc')}</p>
            <a
              href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G5_Inventaire_VTT.pdf"
              target="_blank"
            >
              {t('inventory')}
            </a>
          </span>
        </>
      ),
    },
  ];

  return (
    <section className="configuratorStep" id="step2">
      {customMat ? (
        <article className="container medTight">
          <div className="titles">
            <h3 className="centeredDashed">{t('step2.customTitle')}</h3>
            <p>{t('step2.customDesc')}</p>
          </div>
          <div className="checkboxes">
            <legend>{t('step2.mat')}</legend>

            <CheckboxInput
              title={t('step2.matDisco')}
              name="discoMat"
              checked={discoMat}
              updateSingleField={updateFields}
            />

            <CheckboxInput
              title={t('step2.matCine')}
              name="cinemaMat"
              checked={cinemaMat}
              updateSingleField={updateFields}
            />

            <CheckboxInput
              title={t('step2.matSport')}
              name="sportsMat"
              checked={sportsMat}
              updateSingleField={updateFields}
            />

            <legend>{t('mtbs.title')}</legend>

            <div className="inline-numbers">
              <NumberInput
                title={t('step2.bikesFor')}
                name="bikeNb"
                value={bikeNb}
                updateSingleField={updateFields}
              />

              <NumberInput
                title={t('step2.days')}
                name="bikeDays"
                value={bikeDays}
                updateSingleField={updateFields}
              />
            </div>
          </div>
        </article>
      ) : (
        <>
          <article className="container medTight">
            <div className="titles">
              <h3 className="centeredDashed">{t('step2.title')}</h3>
              <p>{t('step2.desc')}</p>
            </div>
            <div className="cardsContainer">
              <RadioPriceCard
                name="activityPack"
                title=""
                options={cardOptions}
                selectedOption="s"
                updateSingleField={updateFields}
              />
            </div>
          </article>
          <article className="container tight">
            <h3 className="centeredDashed">{t('mtbs.title')}</h3>
            <p>{t('mtbs.desc')}</p>
            <BooleanInput
              name="mtbs"
              title={t('mtbs.label')}
              initValue={mtbs}
              updateSingleField={updateFields}
            />
            <small>{t('mtbs.small')}</small>
            <a
              href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G5_Inventaire_VTT.pdf"
              target="_blank"
            >
              {t('mtbs.link')}
            </a>
          </article>
        </>
      )}

      <hr />

      {customMat ? (
        <div id="changePack" className="cta cta--config">
          <div className="ctaContent">
            <div className="ctaContentTexts">
              <h4>{t('step2.ctaPackSSurtitle')}</h4>
              <h3>{t('step2.ctaPackSTitle')}</h3>
            </div>

            <button className="btn btn--white" type="button" onClick={switchingToCustomMat}>
              {t('step2.ctaPackSBtn')}
            </button>
          </div>
        </div>
      ) : (
        <div id="changePack" className="cta cta--config">
          <div className="ctaContent">
            <div className="ctaContentTexts">
              <h4>{t('step2.ctaCustomSurtitle')}</h4>
              <h3>{t('step2.ctaCustomTitle')}</h3>
            </div>

            <button className="btn btn--white" type="button" onClick={switchingToCustomMat}>
              {t('step2.ctaCustomBtn')}
            </button>
          </div>
        </div>
      )}

      <div id="changePack" className="cta cta--config">
        <div className="ctaContent">
          <div className="ctaContentTexts">
            <h4>{t('step2.ctaUpgradeSurtitle')}</h4>
            <h3>{t('step2.ctaUpgradeTitle')}</h3>
          </div>

          <button className="btn btn--white" type="button" onClick={upgradeToPack}>
            {t('step2.ctaUpgradeBtn')}
          </button>
        </div>
      </div>

      <hr />
    </section>
  );
}
