import React from 'react';

type TextInputType = {
  title: string;
  name: string;
  value?: string;
  textarea?: boolean;
  placeholder?: string;
  info?: string;
  validation: {
    regex?: string;
    minLength?: number;
    maxLength?: number;
  };
  required?: boolean;
  addClass?: string;
};

type TextInputProps = TextInputType & {
  updateSingleField: (fields: Partial<TextInputType>) => void;
};

export default function TextInput({
  title,
  name,
  placeholder,
  info,
  validation,
  required,
  addClass,
  value,
  textarea,
  updateSingleField,
}: TextInputProps) {
  if (textarea) {
    return (
      <div className="input input__text input__textarea">
        <label htmlFor={name}>
          <textarea
            name={name}
            id={name}
            cols={30}
            rows={10}
            required={required}
            value={value}
            onChange={(e) => updateSingleField({ [name]: e.target.value })}
          />{' '}
          <b>{title}</b>
        </label>
      </div>
    );
  }
  return (
    <div className={`input input__text ${addClass}`}>
      <label htmlFor={name}>
        <input
          type="text"
          name={name}
          id={name}
          minLength={validation.minLength}
          maxLength={validation.maxLength}
          pattern={validation.regex}
          placeholder={placeholder}
          onChange={(e) => updateSingleField({ [name]: e.target.value })}
          required={required}
          value={value}
        />
        <span>{title}</span>
      </label>
      <small>{info}</small>
    </div>
  );
}
