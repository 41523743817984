import React from 'react';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import TeamDetails from '../components/TeamDetails';

export default function Team() {
  const { t } = useTranslation('', { keyPrefix: 'Team' });

  return (
    <main id="team">
      <Hero page="Team" imgPath="../assets/layouts/team/team-cover.webp" />
      <section id="teamIntro" className="container">
        <div className="row">
          <div className="col-md-4 imgCol">
            {/* <img src="../assets/layouts/hero-test-cover.jpg" alt="Notre équipe" /> */}
            <img src="../assets/layouts/team/team-intro.webp" alt="Notre équipe" />
          </div>
          <div className="col-md-8 textCol">
            <h2>{t('intro.title')}</h2>
            <h4>{t('intro.subtitle')}</h4>
            <p>{t('intro.description')}</p>
            {/*
            <ImgButton
              to="/activities"
              label={t('intro.btn')}
              picto="archer_white"
              btnType="btn--primary"
            />
  */}
          </div>
        </div>
      </section>
      <section id="teamShorts">
        <div className="teamGradient" />

        <TeamDetails />
      </section>
    </main>
  );
}
