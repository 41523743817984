import React from 'react';
import { useTranslation } from 'react-i18next';

import { Link } from 'react-router-dom';

type StatusType = {
  status?: 'sending' | 'success' | 'error' | 'cooldown';
};

export default function FormStatus({ status }: StatusType) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });

  return (
    <>
      {status === 'success' && (
        <section className="configuratorStep container status__success">
          <h2>Merci!</h2>

          <p>
            Nous traitons votre demande et vous reviendrons au plus vite.
            <br />
            Vous avez reçu un e-mail de confirmation.
            <br />
          </p>

          <Link to="/" className="btn btn--primary">
            Retour à l&rsquo;accueil
          </Link>
        </section>
      )}

      {status === 'error' && (
        <p className="status status__error">
          <i className="fa-solid fa-triangle-exclamation" /> Une erreur est survenue, votre demande
          n&rsquo;a pas pu être envoyée. Merci de contrôler votre adresse e-mail et de réessayer.
          <br />
          Le cas échéant, vous pouvez nous transmettre votre demande à l&rsquo;adresse suivante :{' '}
          <Link to="mailto:contact@les-eterpaz.ch" style={{ color: 'var(--primary-green)' }}>
            <span id="mail" />
          </Link>
        </p>
      )}

      {status === 'sending' && (
        <p className="status status__sending">
          <i className="fa-solid fa-spinner" /> En cours d&rsquo;envoi...
        </p>
      )}

      {status === 'cooldown' && (
        <p className="status status__sending">
          Afin d&rsquo;éviter le spam, nous vous demandons de patienter quelques instants avant de
          renvoyer votre demande.
        </p>
      )}
    </>
  );
}
