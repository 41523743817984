import React from 'react';

interface PanoramaProps {
  img: string;
}

export default function Panorama(props: PanoramaProps) {
  const { img } = props;
  return (
    <div className="panorama">
      <img src={`/assets/layouts/${img}.svg`} alt="" />
    </div>
  );
}
