import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface GalleryProps {
  id: string;
}

export default function Gallery(props: GalleryProps) {
  const { id } = props;

  const { t } = useTranslation('', { keyPrefix: 'Gallery' });
  const categories = ['0_outside', '1_groundfloor', '2_rooms', '3_lastfloor'];
  // Sketchy stuff, but flemme : min and max ids for the pics categories
  const intervales = [
    { min: 1, max: 8 },
    { min: 9, max: 31 },
    { min: 32, max: 42 },
    { min: 43, max: 47 },
  ];

  const [selectedCategory, setSelectedCategory] = useState<string>(categories[0]);
  const [selectedImageId, setSelectedImageId] = useState<number>(1);
  const [overlayOpened, setOverlayOpened] = useState<boolean>(false);

  const overlayHandler = (imgKey: number) => {
    setSelectedImageId(imgKey);
    setOverlayOpened(!overlayOpened);
  };

  const radioHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(evt.target.value);
  };

  const navigation = (direction: number) => {
    if (selectedImageId + direction < intervales[0].min) {
      setSelectedImageId(intervales[3].max);
      setSelectedCategory(categories[3]);
      return;
    }

    if (selectedImageId + direction > intervales[3].max) {
      setSelectedImageId(intervales[0].min);
      setSelectedCategory(categories[0]);
      return;
    }

    if (
      selectedImageId + direction >= intervales[0].min &&
      selectedImageId + direction <= intervales[0].max
    ) {
      setSelectedCategory(categories[0]);
    }

    if (
      selectedImageId + direction >= intervales[1].min &&
      selectedImageId + direction <= intervales[1].max
    ) {
      setSelectedCategory(categories[1]);
    }

    if (
      selectedImageId + direction >= intervales[2].min &&
      selectedImageId + direction <= intervales[2].max
    ) {
      setSelectedCategory(categories[2]);
    }

    if (selectedImageId + direction >= intervales[3].min) {
      setSelectedCategory(categories[3]);
    }

    setSelectedImageId(selectedImageId + direction);
  };

  const keyboardNavigation = (evt: React.KeyboardEvent) => {
    switch (evt.key) {
      case 'Escape':
        setOverlayOpened(false);
        break;

      case 'ArrowRight':
        navigation(1);
        break;

      case 'ArrowLeft':
        navigation(-1);
        break;

      default:
        break;
    }
  };

  return (
    <section id={id} className="container gallery">
      <h2>{t('title')}</h2>
      <fieldset className="btnLine">
        {categories.map((category) => (
          <label
            key={category}
            htmlFor={category}
            className={category === selectedCategory ? 'checked' : ''}
          >
            <input
              type="radio"
              name="category"
              id={category}
              value={category}
              checked={selectedCategory === category}
              onChange={radioHandler}
            />
            {t(`categories.${category}.label`)}
          </label>
        ))}
      </fieldset>
      <div className="images">
        {categories.map((category) => (
          <div
            key={`imgCat_${category}`}
            id={category}
            className={category === selectedCategory ? 'category' : 'category d-none'}
          >
            {Object.keys(t(`categories.${category}.images`, { returnObjects: true })).map(
              (imgKey) => (
                <div
                  key={`${category}-${imgKey}`}
                  aria-hidden
                  className="image"
                  onClick={() => overlayHandler(parseInt(imgKey, 10))}
                  style={{
                    backgroundImage: `url(./assets/gallery/${category}/min/Les-Eterpaz-2022-${imgKey}.webp)`,
                  }}
                />
              )
            )}
          </div>
        ))}
      </div>

      {overlayOpened ? (
        <div
          id="overlay"
          ref={(div) => div?.focus()}
          aria-hidden
          onKeyDown={(evt) => keyboardNavigation(evt)}
          tabIndex={-1}
        >
          <div className="overlayBackground" aria-hidden onClick={() => setOverlayOpened(false)} />
          <button
            type="button"
            className="btnClose"
            title={t('close') || 'Close'}
            onClick={() => setOverlayOpened(false)}
          >
            <i className="fa-solid fa-xmark" />
          </button>
          <div className="imgHolder">
            <img
              src={`./assets/gallery/${selectedCategory}/Les-Eterpaz-2022-${selectedImageId}.webp`}
              alt=""
            />
            <div className="imgDescription">
              <p>{t(`categories.${selectedCategory}.images.${selectedImageId}`)}</p>
              <p className="counter">
                {selectedImageId}/{intervales[3].max}
              </p>
            </div>
            <div className="nav">
              <button type="button" className="navBtn navBtnPrev" onClick={() => navigation(-1)}>
                <i className="fa-solid fa-angle-left" />
              </button>
              <button type="button" className="navBtn navBtnNext" onClick={() => navigation(1)}>
                <i className="fa-solid fa-angle-right" />
              </button>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
    </section>
  );
}
