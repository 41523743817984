import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

interface FloorsProps {
  floors: object;
}

export default function FloorsPreview(props: FloorsProps) {
  const { floors } = props;
  const [selectedFloor, setSelectedFloor] = useState<number>(0);

  const radioHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFloor(parseInt(evt.target.value, 10));
  };

  const { t } = useTranslation('', { keyPrefix: 'Camp' });

  return (
    <div id="campBuildingFloors">
      <h3>{t('floorsPlan')}</h3>

      <div className="colRight">
        <fieldset className="btnLine">
          {Object.keys(floors).map((floor) => (
            <label
              key={floor}
              htmlFor={`floor${floor}`}
              className={`${parseInt(floor, 10) === selectedFloor ? 'checked' : ''}`}
            >
              <input
                type="radio"
                name="floor"
                id={`floor${floor}`}
                value={floor}
                checked={selectedFloor === parseInt(floor, 10)}
                onChange={radioHandler}
              />
              {t(`floors.${floor}.name`)}
            </label>
          ))}
        </fieldset>
        {Object.keys(floors).map((floor) => (
          <div
            key={`floorDesc${floor}`}
            className={`floorDescription ${parseInt(floor, 10) === selectedFloor ? '' : 'd-none'}`}
          >
            <h6>{t(`floors.${floor}.name`)}</h6>
            {Object.keys(t(`floors.${floor}.details`, { returnObjects: true })).map((detail) => (
              <span key={`floorDescItem${detail}`}>
                {t(`floors.${floor}.details.${detail}.link`).length !== 0 ? (
                  <a href={t(`floors.${floor}.details.${detail}.link`) || '#'}>
                    {t(`floors.${floor}.details.${detail}.desc`)}
                  </a>
                ) : (
                  t(`floors.${floor}.details.${detail}.desc`)
                )}
              </span>
            ))}
          </div>
        ))}
      </div>
      <div className="colLeft">
        {Object.keys(floors).map((floor) => (
          <img
            key={`img${floor}`}
            src={t(`floors.${floor}.img`) || ''}
            alt=""
            className={parseInt(floor, 10) === selectedFloor ? '' : 'd-none'}
          />
        ))}

        <a href="../assets/floors/eterpaz-floors.pdf" download>
          {t('downloadFloorPlan')}
        </a>

        <a href="../assets/floors/eterpaz-exterieur.pdf" download>
          {t('downloadExtPlan')}
        </a>
      </div>
    </div>
  );
}
