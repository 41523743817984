import React from 'react';

type ProgressBarProps = {
  stepsNb: number;
  currentIndex: number;
};

export default function ProgressBar(props: ProgressBarProps) {
  const { stepsNb, currentIndex } = props;

  const buffer = 5; // percents

  const getWidth = (): number => {
    if (currentIndex === 0) return buffer;

    if (currentIndex + 1 === stepsNb) return 100 - buffer;

    return (currentIndex / stepsNb) * 100;
  };

  return (
    <div className="progressBar">
      {/* <div className="progressBar__background" style={{ width: `${percentages[currentIndex]}` }} /> */}
      <div className="progressBar__background" style={{ width: `${getWidth()}%` }} />
    </div>
  );
}
