import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

type Member = {
  slug: string;
  imgUrl: string;
};

export default function TeamDetails() {
  const [selectedMember, setSelectedMember] = useState<string>();

  const detailsHandler = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedMember(evt.target.value);
  };

  const { t } = useTranslation('', { keyPrefix: 'Team' });

  const members: Member[] = [
    { slug: 'laurent', imgUrl: '/assets/layouts/team/Laurent.webp' },
    { slug: 'fabrice', imgUrl: '/assets/layouts/team/Fabrice.webp' },
    { slug: 'isabelle', imgUrl: '/assets/layouts/team/Isabelle.webp' },
    { slug: 'sabrina', imgUrl: '/assets/layouts/team/Sabrina_alt.webp' },
  ];

  return (
    <div id="TeamDetails" className="container">
      <div className="row members">
        {members.map((member) => (
          <div className="teamMember" key={member.slug}>
            <div className="innerShadow" style={{ backgroundImage: `url(${member.imgUrl})` }} />

            <h3>{t(`TeamMembers.${member.slug}.name`)}</h3>
            <p>{t(`TeamMembers.${member.slug}.desc`)}</p>
            {t(`TeamMembers.${member.slug}.descLong`) ? (
              <label
                htmlFor={member.slug}
                className={`d-none d-md-flex ${member.slug === selectedMember ? 'checked' : ''}`}
              >
                <input
                  type="radio"
                  name="teamMember"
                  id={member.slug}
                  value={member.slug}
                  checked={selectedMember === member.slug}
                  onChange={detailsHandler}
                />
                <i className="fa-solid fa-plus" />
              </label>
            ) : (
              ''
            )}
          </div>
        ))}
      </div>
      <h4>
        {t('discover')} {selectedMember ? t(`TeamMembers.${selectedMember}.name`) : t('ourTeam')}
      </h4>
      <div className="btnLine d-flex d-md-none">
        {members.map((member) => (
          <label
            key={`input-${member.slug}`}
            htmlFor={member.slug}
            className={member.slug === selectedMember ? 'checked' : ''}
          >
            <input
              type="radio"
              name="floor"
              id={member.slug}
              value={member.slug}
              checked={selectedMember === member.slug}
              onChange={detailsHandler}
            />
            {t(`TeamMembers.${member.slug}.name`)}
          </label>
        ))}
      </div>
      <div className="desc">
        {members.map((member) => (
          <p
            key={`desc-${member.slug}`}
            className={`${member.slug === selectedMember ? '' : 'hide-away'}`}
          >
            {t(`TeamMembers.${member.slug}.descLong`)}
          </p>
        ))}
      </div>
    </div>
  );
}
