import { FormInputsType } from '../input/FormType';
import { SummaryType } from '../input/SummaryType';

export default async function sendingRequest(
  d: FormInputsType & SummaryType,
  to: string
): Promise<boolean> {
  // Form the request for sending data to the server.
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer t1y9wr7rRZzVHiUnm02WhwYxheYuusQC',
    },

    body: JSON.stringify(d),
  };

  try {
    const response = await fetch(to, options);
    if (!response.ok) {
      return false;
    }
    return true;
  } catch (error) {
    return false;
  }
}
