import React from 'react';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import ImgButton from '../components/ImgButton';

export default function Lpd() {
  const { t } = useTranslation('', { keyPrefix: 'LPD' });

  return (
    <main id="cga" className="lpd">
      <Hero page="LPD" imgPath="../assets/layouts/team-cover.jpg" panorama="panorama-light-green" />

      <section className="container medTight">
        <h2>{t(`section1.title`)}</h2>
        <p>{t(`section1.text1`)}</p>
        <p>{t(`section1.text2`)}</p>
      </section>

      <section className="container medTight">
        <h2>{t(`section2.title`)}</h2>
        <p>{t(`section2.text1`)}</p>
        <p>{t(`section2.text2`)}</p>
      </section>

      <section className="container medTight">
        <h2>{t(`section3.title`)}</h2>
        <p>{t(`section3.text1`)}</p>
      </section>

      <section className="container medTight">
        <h2>{t(`section4a.title`)}</h2>
        <h3>{t(`section4a.h3`)}</h3>
        <p>{t(`section4a.text1`)}</p>
        <p className="list">{t(`section4a.list1`)}</p>
        <p>{t(`section4a.text2`)}</p>
        <p>{t(`section4a.text3`)}</p>

        <h3>{t(`section4b.h3`)}</h3>
        <p>{t(`section4b.text1`)}</p>
        <p className="list">{t(`section4b.list1`)}</p>
        <p className="list">{t(`section4b.list2`)}</p>
        <p className="list">{t(`section4b.list3`)}</p>
        <p>{t(`section4b.text2`)}</p>
        <p>{t(`section4b.text3`)}</p>

        <h3>{t(`section4c.h3`)}</h3>
        <p>{t(`section4c.text1`)}</p>
        <p>{t(`section4c.text2`)}</p>
        <p className="list">{t(`section4c.list1`)}</p>
        <p>
          {t(`section4c.text3`)}
          <a
            href="https://www.infomaniak.com/fr/cgv/reglement-general-protection-donnees"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('section4c.here')}
          </a>
          .
        </p>
      </section>

      <section className="container medTight">
        <h2>{t(`section5.title`)}</h2>
        <p>{t(`section5.text1`)}</p>
        <ImgButton to="/" label={t('link')} picto="maison_white" btnType="btn--primary" />
      </section>
    </main>
  );
}
