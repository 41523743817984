import React from 'react';
import { useTranslation } from 'react-i18next';
import Panorama from './Panorama';

interface HeroProps {
  page: string;
  imgPath: string;
  full?: boolean;
  panorama?: string;
  videoUrl?: string;
}

export default function Hero({ page, imgPath, full, panorama, videoUrl }: HeroProps) {
  const { t } = useTranslation('', { keyPrefix: page });

  return (
    <div
      className={`hero ${full ? 'hero--full' : 'hero--demi'}`}
      style={{ backgroundImage: `url(${imgPath})` }}
    >
      {videoUrl && (
        <video className="videoBackground" autoPlay muted loop aria-hidden>
          <source src={videoUrl} type="video/mp4" />
        </video>
      )}
      <div className="hero__content">
        <h1>{t('mainTitle')}</h1>
        <h4>{t('subtitle')}</h4>
      </div>

      <Panorama img={panorama || 'panorama-white'} />
    </div>
  );
}
