/* eslint-disable camelcase */
import React, { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import BooleanInput from '../../input/BooleanInput';
import NumberInput from '../../input/NumberInput';

import { FormInputsType } from '../../input/FormType';
import RadioPriceCard from '../../input/RadioPriceCard';
import CheckboxInput from '../../input/CheckboxInput';
import SelectInput from '../../input/SelectInput';

type Step3Type = {
  // Step 2
  mtbs: boolean;

  // Step 3
  activityPack: 's' | 'm' | 'l' | 'classique' | null;
  animations_bow: boolean;
  animations_mtb: boolean;
  animations_nature: boolean;
  animations_forest: boolean;
  animations_biathlon: boolean;
  animations_olympiade: boolean;
  animations_jeuDeLOie: boolean;

  visits_electrobroc: boolean;
  visits_maisonDuGruyere: boolean;
  visits_maisonCailler: boolean;
  visits_chateauDeGruyere: boolean;
  visits_chateauDeGruyereDetails: 'simple' | 'guided' | 'narrated' | 'treasure';
  visits_classNb: number;
};

type Step3Props = Step3Type & {
  updateFields: (fields: Partial<FormInputsType>) => void;
};

export default function Step3({
  mtbs,
  activityPack,
  animations_bow,
  animations_mtb,
  animations_nature,
  animations_forest,
  animations_biathlon,
  animations_olympiade,
  animations_jeuDeLOie,
  visits_electrobroc,
  visits_maisonDuGruyere,
  visits_maisonCailler,
  visits_chateauDeGruyere,
  visits_chateauDeGruyereDetails,
  visits_classNb,
  updateFields,
}: Step3Props) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });
  const tPacks = useTranslation('', { keyPrefix: 'Packs' }).t;

  const cardOptions: {
    value: string;
    title: string;
    subtitle: string;
    surtitle?: string;
    price: number;
    priceText?: string;
    body: string | ReactElement;
  }[] = [
    {
      value: 'classique',
      surtitle: ' ',
      title: `${t('packs.classique.title')} »`,
      subtitle: t('packs.classique.subtitle'),
      priceText: t('packs.classique.priceText') || '',
      price: 52,
      body: (
        <>
          <b>{t('packs.classique.s1title')}</b>
          <p>{t('packs.classique.s1desc')}</p>
          <hr />
          <b>{t('packs.classique.s2title')}</b>
          <p>{t('packs.classique.s2desc')}</p>
          <small>{t('packs.classique.s2sm')}</small>
          <hr />
          <b>{t('packs.classique.s3title')}</b>
          <p>{t('packs.classique.s3desc')}</p>
        </>
      ),
    },
    {
      value: 'l',
      surtitle: `${tPacks('activities.2.surname')}${mtbs ? '+' : ''}`,
      title: tPacks('activities.2.name'),
      subtitle: t('packs.l.subtitle'),
      priceText: `${t('packs.l.priceText')}${mtbs ? `, ${t('mtbs.withMtb')}` : ''}`,
      price: mtbs ? 27 : 20,
      body: (
        <>
          <b>{`${t('packs.m.title')}${mtbs ? '+' : ''} » ${t('packs.m.priceText')}`}</b>
          <hr />
          <b>{t('packs.l.s1title')}</b>
          <p>{t('packs.l.s1desc')}</p>
          <a href="/cga-pdf/2_ConditionsEtTarifs/E_Packs/E4_PackL.pdf" target="_blank">
            {t('packs.l.s1link')}
          </a>
          <hr />
          <b>{t('packs.l.s2title')}</b>
          <p>{t('packs.l.s2desc')}</p>
          <hr />
          <b>{t('packs.l.s3title')}</b>
          <p>{t('packs.l.s3desc')}</p>
        </>
      ),
    },

    {
      value: 'm',
      surtitle: `${tPacks('activities.1.surname')}${mtbs ? '+' : ''}`,

      title: tPacks('activities.1.name'),
      subtitle: t('packs.m.subtitle'),
      priceText: `${t('packs.m.priceText')}${mtbs ? `, ${t('mtbs.withMtb')}` : ''}`,
      price: mtbs ? 22 : 15,
      body: (
        <>
          <b>{`${t('packs.s.title')}${mtbs ? '+' : ''} » ${t('packs.s.priceText')}`}</b>
          <hr />
          <b>{t('packs.m.s1title')}</b>
          <p>{t('packs.m.s1desc')}</p>
          <a href="/cga-pdf/2_ConditionsEtTarifs/E_Packs/E3_PackM.pdf" target="_blank">
            {t('packs.m.s1link')}
          </a>
          <hr />
          <p>
            <b>{t('packs.m.s21title')}</b>
            <br />
            {t('packs.m.s21desc')}
          </p>
          <p>
            <b>{t('packs.m.s22title')}</b>
            <br />
            {t('packs.m.s22desc')}
          </p>
          <p>
            <b>{t('packs.m.s23title')}</b>
            <br />
            {t('packs.m.s23desc')}
          </p>
          <hr />
          <b>{t('packs.m.s3title')}</b>
          <p>{t('packs.m.s3desc')}</p>
        </>
      ),
    },
    {
      value: 's',
      surtitle: `${tPacks('activities.0.surname')}${mtbs ? '+' : ''}`,
      title: tPacks('activities.0.name'),
      subtitle: t('packs.s.subtitle'),
      priceText: `${t('packs.s.priceText')}${mtbs ? `, ${t('mtbs.withMtb')}` : ''}`,
      price: mtbs ? 15 : 8,
      body: (
        <>
          <b>{t('packs.s.s1title')}</b>
          <p>{t('packs.s.s1desc')}</p>

          <a
            href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G2_Inventaire_MatSportJeux.pdf"
            target="_blank"
          >
            {t('inventory')}
          </a>
          <hr />
          <b>{t('packs.s.s2title')}</b>
          <p>{t('packs.s.s2desc')}</p>
          <a
            href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G3_Inventaire_MatDisco.pdf"
            target="_blank"
          >
            {t('inventory')}
          </a>
          <hr />
          <b>{t('packs.s.s3title')}</b>
          <p>{t('packs.s.s3desc')}</p>
          <a
            href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G4_Inventaire_MatCinema.pdf"
            target="_blank"
          >
            {t('inventory')}
          </a>
          <hr />
          <span className={`${mtbs || 'notAvailable'}`}>
            <b>{t('packs.s.s4title')}</b>
            <p>{t('packs.s.s4desc')}</p>
            <a
              href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G5_Inventaire_VTT.pdf"
              target="_blank"
            >
              {t('inventory')}
            </a>
          </span>
        </>
      ),
    },
  ];

  const uncheckAllFields: Partial<FormInputsType> = {
    animations_bow: false,
    animations_mtb: false,
    animations_nature: false,
    animations_forest: false,
    animations_biathlon: false,
    animations_olympiade: false,
    animations_jeuDeLOie: false,
    visits_electrobroc: false,
    visits_maisonDuGruyere: false,
    visits_maisonCailler: false,
    visits_chateauDeGruyere: false,
    visits_chateauDeGruyereDetails: 'simple',
  };

  const classicFields: Partial<FormInputsType> = {
    animations_bow: false,
    animations_mtb: false,
    animations_nature: false,
    animations_forest: false,
    animations_biathlon: false,
    animations_olympiade: false,
    animations_jeuDeLOie: false,
    visits_electrobroc: true,
    visits_maisonDuGruyere: false,
    visits_maisonCailler: true,
    visits_chateauDeGruyere: false,
    visits_chateauDeGruyereDetails: 'simple',
  };

  const updatePacks = (fields: Partial<FormInputsType>) => {
    switch (fields.activityPack) {
      case 'l':
        updateFields(uncheckAllFields);
        break;

      case 'classique':
        updateFields(classicFields);
        break;

      default:
        break;
    }
    updateFields(fields);
  };

  return (
    <div id="step3">
      <section className="configuratorStep">
        <article className="configuratorPacks">
          <div className="titles">
            <h3 className="centeredDashed">{t('step3.title')}</h3>
            <p>{t('step3.desc')}</p>
          </div>
          <div className="carouselHolder">
            <RadioPriceCard
              name="activityPack"
              title=""
              addClass="activities"
              options={cardOptions}
              selectedOption={activityPack || 'l'}
              updateSingleField={updatePacks}
            />
          </div>
        </article>
        <article className="container tight">
          <h3 className="centeredDashed">{t('mtbs.title')}</h3>
          {activityPack === 'classique' ? (
            <p>{t('mtbs.includedInClassic')}</p>
          ) : (
            <>
              <p>{t('mtbs.desc')}</p>
              <BooleanInput
                name="mtbs"
                title={t('mtbs.label')}
                initValue={mtbs}
                updateSingleField={updateFields}
              />
            </>
          )}
          <small>{t('mtbs.small')}</small>
          <a href="/cga-pdf/2_ConditionsEtTarifs/G_Materiel/G5_Inventaire_VTT.pdf" target="_blank">
            {t('mtbs.link')}
          </a>
        </article>
      </section>
      <section className="configuratorStep container">
        <article>
          <div className="titles">
            <h3 className="centeredDashed">{t('animations.title')}</h3>
            {activityPack === 'l' && <p>{t('animations.desc')}</p>}
            {activityPack === 'classique' && <p>{t('animations.descPackClassique')}</p>}
            {(activityPack === 's' || activityPack === 'm') && (
              <p className="minNightsWarning">{t('animations.descPackSM')}</p>
            )}
          </div>
          {activityPack !== 'classique' && (
            <div className="checkboxes animations">
              <legend>{t('animations.sportsAnimations')}</legend>
              <CheckboxInput
                title={t('animations.bow')}
                name="animations_bow"
                checked={animations_bow}
                disabled={activityPack !== 'l'}
                updateSingleField={updateFields}
              />
              <div>
                <CheckboxInput
                  title={t('animations.mtb')}
                  name="animations_mtb"
                  checked={animations_mtb}
                  disabled={activityPack !== 'l'}
                  updateSingleField={updateFields}
                />
                <small className="xs">{t('animations.mtbSmall')}</small>
              </div>
              <legend>{t('animations.natureAnimations')}</legend>
              <CheckboxInput
                title={t('animations.nature')}
                name="animations_nature"
                checked={animations_nature}
                disabled={activityPack !== 'l'}
                updateSingleField={updateFields}
              />
              <CheckboxInput
                title={t('animations.forest')}
                name="animations_forest"
                checked={animations_forest}
                disabled={activityPack !== 'l'}
                updateSingleField={updateFields}
              />
              <small>
                <a
                  href="/cga-pdf/2_ConditionsEtTarifs/F_Animations/F1_CeT_Animations.pdf"
                  target="_blank"
                >
                  {t('animations.natureConditions')}
                </a>
              </small>
              <legend>{t('animations.groupAnimations')}</legend>
              <CheckboxInput
                title={t('animations.biathlon')}
                name="animations_biathlon"
                checked={animations_biathlon}
                disabled={activityPack !== 'l'}
                updateSingleField={updateFields}
              />
              <CheckboxInput
                title={t('animations.olympiade')}
                name="animations_olympiade"
                checked={animations_olympiade}
                disabled={activityPack !== 'l'}
                updateSingleField={updateFields}
              />
              <CheckboxInput
                title={t('animations.jeuDeLOie')}
                name="animations_jeuDeLOie"
                checked={animations_jeuDeLOie}
                disabled={activityPack !== 'l'}
                updateSingleField={updateFields}
              />
              <small>
                <a
                  href="/cga-pdf/2_ConditionsEtTarifs/F_Animations/F2_CeT_JeuxDeGroupe.pdf"
                  target="_blank"
                >
                  {t('animations.groupConditions')}
                </a>
              </small>
            </div>
          )}
        </article>
      </section>
      <section className="container medTight">
        <article>
          <div className="titles">
            <h3 className="centeredDashed">{t('visits.title')}</h3>

            {activityPack === 'l' && <p>{t('visits.desc')}</p>}
            {activityPack === 'classique' && <p>{t('visits.descPackClassique')}</p>}
            {(activityPack === 's' || activityPack === 'm') && (
              <p className="minNightsWarning">{t('visits.descPackSM')}</p>
            )}
          </div>

          {
            /* activityPack !== 'classique' && ( */
            <div className="checkboxes visits">
              <CheckboxInput
                title={`${t('visits.electrobroc')} (${t('visits.free')})`}
                name="visits_electrobroc"
                checked={visits_electrobroc}
                disabled={activityPack !== 'l'}
                extLink="https://www.electrobroc.ch/"
                updateSingleField={updateFields}
              />

              <CheckboxInput
                title={`${t('visits.mc')} (7.- / ${t('visits.kid')})`}
                name="visits_maisonCailler"
                checked={visits_maisonCailler}
                disabled={activityPack !== 'l'}
                extLink="https://cailler.ch/fr/experiences-cailler"
                updateSingleField={updateFields}
              />
              {activityPack === 'classique' && visits_chateauDeGruyere ? (
                ''
              ) : (
                <CheckboxInput
                  title={`${t('visits.mdg')} (3.- / ${t('visits.kid')})`}
                  name="visits_maisonDuGruyere"
                  checked={visits_maisonDuGruyere}
                  disabled={activityPack === 's' || activityPack === 'm'}
                  extLink="https://www.lamaisondugruyere.ch/"
                  updateSingleField={updateFields}
                />
              )}

              {activityPack === 'classique' && visits_maisonDuGruyere ? (
                ''
              ) : (
                <CheckboxInput
                  title={t('visits.gruyeres')}
                  name="visits_chateauDeGruyere"
                  checked={visits_chateauDeGruyere}
                  disabled={activityPack === 's' || activityPack === 'm'}
                  extLink="https://www.chateau-gruyeres.ch/"
                  updateSingleField={updateFields}
                />
              )}

              {visits_chateauDeGruyere && (
                <>
                  <SelectInput
                    title=""
                    name="visits_chateauDeGruyereDetails"
                    disabled={activityPack === 's' || activityPack === 'm'}
                    options={[
                      { name: 'simple', title: `${t('visits.simple')} (${t('visits.free')})` },
                      {
                        name: 'guided',
                        title: `${t('visits.guided')} (90.- / ${t('visits.class')})`,
                      },
                      {
                        name: 'narrated',
                        title: `${t('visits.narrated')} (140.- / ${t('visits.class')})`,
                      },
                      {
                        name: 'treasure',
                        title: `${t('visits.treasure')} (140.- / ${t('visits.class')})`,
                      },
                    ]}
                    updateSingleField={updateFields}
                    value={visits_chateauDeGruyereDetails}
                  />

                  {visits_chateauDeGruyereDetails !== 'simple' && (
                    <p className="classNb">
                      {t('visits.for')}&nbsp;
                      <NumberInput
                        title=""
                        name="visits_classNb"
                        value={visits_classNb}
                        updateSingleField={updateFields}
                      />
                      &nbsp;
                      {t('visits.classes')}.
                    </p>
                  )}
                </>
              )}
              <small className="xs">{t('visits.price')}</small>
            </div>
            /* ) */
          }
        </article>
      </section>
    </div>
  );
}
