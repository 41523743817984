import React from 'react';
import { useTranslation } from 'react-i18next';

import FloorsPreview from '../components/FloorsPreview';
import Gallery from '../components/Gallery';
import Hero from '../components/Hero';
import LeafMap from '../components/LeafMap';

export default function Camp() {
  const { t } = useTranslation('', { keyPrefix: 'Camp' });

  const markers: { id: number; position: [number, number]; imgPath: string }[] = [
    { id: 0, position: [46.595249, 7.099474], imgPath: './assets/pictograms/map/mapHouse.png' },
    {
      id: 13,
      position: [46.60219750771386, 7.092427490080771],
      imgPath: './assets/pictograms/map/mapBus.png',
    },
    {
      id: 14,
      position: [46.6040691906023, 7.098643910877476],
      imgPath: './assets/pictograms/map/mapTrain.png',
    },
  ];

  return (
    <main id="camp">
      <Hero
        page="Camp"
        imgPath="../assets/layouts/camp/camp_hero.webp"
        full
        videoUrl="/assets/videos/camp.mp4"
      />
      <section id="campPresentation" className="container">
        <div className="sectionTitle">
          <h2>{t('presentation.title')}</h2>
          <h4>{t('presentation.surtitle')}</h4>
        </div>
        <div className="desc">
          {Object.keys(t('presentation.description', { returnObjects: true })).map((key) => (
            <p key={`line${key}`}>{t(`presentation.description.${key}`)}</p>
          ))}
        </div>
        <iframe
          className="ytVideo"
          src="https://www.youtube.com/embed/zV0nfjllZ4o?controls=0"
          title="YouTube video player"
        />
      </section>

      <section id="campBuilding" className="container">
        <div className="sectionTitle">
          <h2 className="underlinedTitle">{t('infra.title')}</h2>
        </div>

        <FloorsPreview floors={t('floors', { returnObjects: true })} />

        <div id="campBuildingSpecs" className="row">
          {/* <h3>{t('infra.details.title')}</h3> */}
          {Object.keys(t('infra.details.specs', { returnObjects: true })).map((colKey) => (
            <div className="col-md-4" key={colKey}>
              <h6>{t(`infra.details.specs.${colKey}.title`)}</h6>
              {Object.keys(t(`infra.details.specs.${colKey}.subelts`, { returnObjects: true })).map(
                (listKey) =>
                  Object.keys(
                    t(`infra.details.specs.${colKey}.subelts.${listKey}`, { returnObjects: true })
                  ).map((listEltKey) =>
                    listEltKey === '0' ? (
                      <p className="underlined" key={`${colKey}-${listKey}-${listEltKey}`}>
                        <u>{t(`infra.details.specs.${colKey}.subelts.${listKey}.${listEltKey}`)}</u>
                      </p>
                    ) : (
                      <p key={`${colKey}-${listKey}-${listEltKey}`}>
                        {t(`infra.details.specs.${colKey}.subelts.${listKey}.${listEltKey}`)}
                      </p>
                    )
                  )
              )}
            </div>
          ))}
        </div>
      </section>
      <section id="campSpecs">
        <h3>{t('specs.title')}</h3>
        <div className="specs container">
          {Object.keys(t('specs.list', { returnObjects: true })).map((key) => (
            <div className={`spec spec-${key}`} key={t(`specs.list.${key}.id`)}>
              <details>
                <summary>
                  <strong>{t(`specs.list.${key}.title`)}</strong>
                  <span className="detail">{t('specs.details')}</span>
                </summary>
                <div className="summary-content">
                  {Object.keys(t(`specs.list.${key}.lines`, { returnObjects: true })).map(
                    (lineKey) =>
                      t(`specs.list.${key}.lines.${lineKey}.style`) !== '' ? (
                        <p className="underlined" key={`${key}-${lineKey}`}>
                          <u>{t(`specs.list.${key}.lines.${lineKey}.text`)}</u>
                        </p>
                      ) : (
                        <p key={`${key}-${lineKey}`}>
                          {t(`specs.list.${key}.lines.${lineKey}.text`)}
                        </p>
                      )
                  )}
                </div>
              </details>
            </div>
          ))}
        </div>
      </section>
      <Gallery id="campGallery" />
      <section id="campAccess" className="container">
        <div className="dashedBox">
          <h2>{t('access.title')}</h2>
          <div className="row">
            <div className="col">
              <h3>{t('access.byCarTitle')}</h3>
              <p>{t('access.byCarDesc')}</p>
              <p>
                <a
                  title={t('access.byCarLink') || 'external link'}
                  href="https://www.google.com/maps/dir//Les+Eterpaz,+1636+Broc/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Route des Marches 11
                  <br />
                  CH-1636 Broc
                </a>
              </p>
            </div>
            <div className="col">
              <h3>{t('access.byTrainTitle')}</h3>
              <p>{t('access.byTrainDesc0')}</p>
              <p>
                {t('access.byTrainDesc1')}
                <a
                  title={t('access.byTrainLinkTitle') || 'external link'}
                  href="https://www.sbb.ch/fr/"
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {t('access.byTrainDesc2')}
                </a>
                .
              </p>
            </div>
          </div>
        </div>
        <LeafMap markers={markers} center={[46.595249, 7.099474]} zoom={13} />
      </section>
    </main>
  );
}
