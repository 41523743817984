import React, { ReactElement, useState } from 'react';

type RadioType = {
  name: string;
  title: string;
  pack?: boolean;
  selectedOption?: string;
  addClass?: string;
  options: {
    value: string;
    surtitle?: string;
    title: string;
    subtitle: string;
    price: number;
    priceText?: string;
    body: string | ReactElement;
  }[];
};

type RadioProps = RadioType & {
  updateSingleField: (fields: Partial<RadioType>) => void;
};

export default function RadioPriceCard({
  title,
  name,
  options,
  pack,
  selectedOption,
  addClass,
  updateSingleField,
}: RadioProps) {
  const [selectedRadio, setSelectedRadio] = useState<string>(selectedOption || '');

  const radioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSingleField({ [name]: e.target.value });
    setSelectedRadio(e.target.value);
  };

  const chf = new Intl.NumberFormat('en-CH', {
    style: 'currency',
    currency: 'CHF',
  });

  if (pack)
    return (
      <fieldset className={`input input__cards ${addClass}`}>
        <legend>{title}</legend>
        {options.map((option) => (
          <label
            key={option.value}
            htmlFor={`${option.value}`}
            className={`${option.value === selectedRadio ? 'checked' : ''} priceCard`}
          >
            <input
              type="radio"
              name={name}
              id={`${option.value}`}
              value={option.value}
              checked={selectedRadio === option.value}
              onChange={radioHandler}
            />
            <div className="priceCard__header">
              {option.surtitle && <h5>{option.surtitle}</h5>}
              <h3>{option.title}</h3>
              <small>{option.subtitle}</small>
            </div>
            <h3 className={`priceCard__price ${pack ? 'priceCard__price--pack' : ''}`}>
              {chf.format(option.price)}
            </h3>
            <div className="priceCard__body">{option.body}</div>
          </label>
        ))}
      </fieldset>
    );

  return (
    <fieldset className={`input input__cards ${addClass}`}>
      <legend>{title}</legend>
      {options.map((option) => (
        <label
          key={option.value}
          htmlFor={`${option.value}`}
          className={`${option.value === selectedRadio ? 'checked' : ''} priceCard`}
        >
          <input
            type="radio"
            name={name}
            id={`${option.value}`}
            value={option.value}
            checked={selectedRadio === option.value}
            onChange={radioHandler}
          />
          <div className="priceCard__header">
            {option.surtitle && <h5>{option.surtitle}</h5>}

            <h3>{option.title}</h3>
            <small>{option.subtitle}</small>
          </div>
          <div className="priceCard__price">
            <small>
              <b>{option.priceText}</b>
              <br />
              {option.price}.- / élève
            </small>
          </div>
          <div className="priceCard__body">{option.body}</div>
        </label>
      ))}
    </fieldset>
  );
}
