import React from 'react';
import { Link } from 'react-router-dom';

interface CtaProps {
  tagId: string;
  to: string;
  title: string;
  surtitle: string;
  label: string;
  picto: string;
  btnType: string;
}

export default function Cta(props: CtaProps) {
  const { tagId, to, label, picto, btnType, title, surtitle } = props;

  return (
    <div id={tagId} className="cta">
      <div className="ctaGradient" />
      <div className="ctaContent">
        <div className="ctaContentTexts">
          <h4>{surtitle}</h4>
          <h3>{title}</h3>
        </div>

        <Link to={to} className={`btn ${btnType}`}>
          <i className={`fa-regular ${picto}`} />
          {label}
        </Link>
      </div>
    </div>
  );
}
