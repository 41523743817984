import React, { useState } from 'react';

type RadioType = {
  name: string;
  title: string;
  selectedOption: string;
  options: { value: string; title: string; locked: boolean }[];
};

type RadioProps = RadioType & {
  updateSingleField: (fields: Partial<RadioType>) => void;
};

export default function RadioInput({
  title,
  name,
  options,
  selectedOption,
  updateSingleField,
}: RadioProps) {
  const [selectedRadio, setSelectedRadio] = useState<string>(selectedOption || '');

  const radioHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    updateSingleField({ [name]: e.target.value });
    setSelectedRadio(e.target.value);
  };

  return (
    <fieldset className="input input__radio">
      <legend>{title}</legend>
      {options.map((option) => (
        <label
          key={option.value}
          htmlFor={`${option.value}`}
          className={`${option.value === selectedRadio ? 'checked' : ''} ${
            option.locked ? 'disabled' : ''
          }`}
        >
          <input
            type="radio"
            name={name}
            id={`${option.value}`}
            value={option.value}
            checked={selectedRadio === option.value}
            disabled={option.locked}
            onChange={radioHandler}
          />
          {option.title}
        </label>
      ))}
    </fieldset>
  );
}
