import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LanguageSelector from '../input/LanguageSelector';
import MobileMenu from './MobileMenu';

export default function MainMenu() {
  const [burgerOpen, setBurgerOpen] = useState(false);

  const toggleBurger = () => {
    setBurgerOpen(!burgerOpen);
  };

  const { t } = useTranslation('', { keyPrefix: 'MainMenu' });
  const location = useLocation();

  return (
    <nav>
      <div className="menu">
        <div className="menuLeft">
          <Link to="/" className="logo">
            <img src="/assets/logos/Black+Colors_Thick.svg" alt="Logo" />
          </Link>
        </div>
        <div className="menuRight">
          <Link
            to="/"
            title={t('home') || ''}
            className={location.pathname === '/' ? 'selected' : ''}
          >
            {t('home')}
          </Link>
          <Link
            to="/camp"
            title={t('camp') || ''}
            className={location.pathname === '/camp' ? 'selected' : ''}
          >
            {t('camp')}
          </Link>
          <Link
            to="/activities"
            title={t('activities') || ''}
            className={location.pathname === '/activities' ? 'selected' : ''}
          >
            {t('activities')}
          </Link>
          <Link
            to="/cga"
            title={t('cga') || ''}
            className={location.pathname === '/cga' ? 'selected' : ''}
          >
            {t('cga')}
          </Link>
          <Link
            to="/team"
            title={t('team') || ''}
            className={location.pathname === '/team' ? 'selected' : ''}
          >
            {t('team')}
          </Link>
          <Link to="/configurator" className="btn btn--primary btn--menu">
            <i className="fa-regular fa-calendar-days" />
            {t('reserve')}
          </Link>
          <LanguageSelector />
        </div>
        <button
          type="button"
          id="burgerBtn"
          className={`menuBurger ${burgerOpen ? 'isOpen' : 'notOpen'}`}
          onClick={toggleBurger}
          aria-label="Navigation"
        >
          <span className="burger" />
          <span className="burger" />
          <span className="burger" />
          <span className="iconLabel" />
        </button>
      </div>
      <MobileMenu isOpen={burgerOpen}>
        <Link
          to="/"
          className={location.pathname === '/home' ? 'selected' : ''}
          onClick={toggleBurger}
        >
          {t('home')}
        </Link>
        <Link
          to="/camp"
          className={location.pathname === '/camp' ? 'selected' : ''}
          onClick={toggleBurger}
        >
          {t('camp')}
        </Link>
        <Link
          to="/activities"
          className={location.pathname === '/activities' ? 'selected' : ''}
          onClick={toggleBurger}
        >
          {t('activities')}
        </Link>
        <Link
          to="/cga"
          className={location.pathname === '/cga' ? 'selected' : ''}
          onClick={toggleBurger}
        >
          {t('cga')}
        </Link>
        <Link
          to="/team"
          className={location.pathname === '/team' ? 'selected' : ''}
          onClick={toggleBurger}
        >
          {t('team')}
        </Link>
        <Link to="/configurator" className="btn btn--primary" onClick={toggleBurger}>
          <i className="fa-regular fa-calendar-days" />
          {t('reserve')}
        </Link>
      </MobileMenu>
    </nav>
  );
}
