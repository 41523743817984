import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Pack from './Pack';

type CarouselProps = {
  localeKey: string;
};

export default function Carousel({ localeKey }: CarouselProps) {
  const { t } = useTranslation('', { keyPrefix: 'Packs' });
  return (
    <div className="carousel">
      <div className="packs">
        {Object.keys(t(localeKey, { returnObjects: true })).map((key) => (
          <Pack
            key={`pack${key}`}
            title={t(`${localeKey}.${key}.name`)}
            surname={t(`${localeKey}.${key}.surname`)}
            subtitle={t(`${localeKey}.${key}.subtitle`)}
            price={t(`${localeKey}.${key}.price`)}
            headerSubtitle={t(`${localeKey}.${key}.headerSubtitle`)}
          >
            {Object.keys(t(`${localeKey}.${key}.items`, { returnObjects: true })).map((itemKey) => (
              <div className="item" key={`pack${key}-${itemKey}`}>
                {itemKey === '0' ? '' : <hr />}
                <h6>{t(`${localeKey}.${key}.items.${itemKey}.title`)}</h6>
                <p>{t(`${localeKey}.${key}.items.${itemKey}.desc`)}</p>
                {t(`${localeKey}.${key}.items.${itemKey}.link`) ? (
                  <Link to={t(`${localeKey}.${key}.items.${itemKey}.link`) || '#'}>
                    {t(`${localeKey}.${key}.items.${itemKey}.textLink`)}
                  </Link>
                ) : (
                  ''
                )}
              </div>
            ))}
          </Pack>
        ))}
      </div>
    </div>
  );
}
