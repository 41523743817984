export function Today(): string {
  // Date object
  const date = new Date();

  const currentDay = String(date.getDate()).padStart(2, '0');
  const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
  const currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY

  return `${currentYear}-${currentMonth}-${currentDay}`;
}

export function formatDate(date: string, locale?: string): string {
  const formattedDate = new Date(date);

  return formattedDate.toLocaleDateString(locale || 'fr');
}

export function AddDays(originalDate: string, days: number): string {
  // Date object
  const date = new Date(originalDate);
  date.setDate(date.getDate() + days);

  const currentDay = String(date.getDate()).padStart(2, '0');
  const currentMonth = String(date.getMonth() + 1).padStart(2, '0');
  const currentYear = date.getFullYear();

  // we will display the date as DD-MM-YYYY
  return `${currentYear}-${currentMonth}-${currentDay}`;
}

export function DateDifference(d1: string, d2: string): number {
  return (new Date(d2).getTime() - new Date(d1).getTime()) / (1000 * 3600 * 24);
}

/* ### Saisons ###
  été : 1 Juillet - 31 Août
  automne : octobre
  hiver : mi-décembre / mi-janvier
  paques : avril
  carnaval : mi- à fin février
*/

export function isWeekEnd(d1: string, d2: string): boolean {
  // Cas de WE :
  let flag = true;
  // - Date 1 doit être vendredi ou samedi
  if (new Date(d1).getDay() !== 5 && new Date(d1).getDay() !== 6) flag = false;
  // - Date 2 doit être dimanche (?)
  if (new Date(d2).getDay() !== 0) flag = false;
  // - Date 2 doit être le lendemain ou surlendemain de Date 1
  if (DateDifference(d1, d2) > 2) flag = false;
  return flag;
}

export function GetSeason(
  d1: string,
  d2: string
): 'summer' | 'autumn' | 'winter' | 'easter' | 'february' | null {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  // Si l'arrivée est en juillet ou après, et le départ en août ou avant ->
  if (date1.getMonth() <= 7 && date2.getMonth() >= 6) {
    return 'summer';
  }

  if (date1.getMonth() <= 9 && date2.getMonth() >= 9) {
    return 'autumn';
  }

  if (date1.getMonth() <= 3 && date2.getMonth() >= 3) {
    return 'easter';
  }

  if (
    date1.getMonth() <= 1 &&
    date1.getDate() <= 29 &&
    date2.getMonth() >= 1 &&
    date2.getDate() >= 15
  ) {
    return 'february';
  }

  if (
    (date1.getMonth() === 11 || date1.getMonth() === 0) &&
    (date2.getMonth() === 11 || date2.getMonth() === 0)
  ) {
    return 'winter';
  }

  return null;
}

export function GetAccessChargesSeason(
  d1: string,
  d2: string
): 'summer' | 'autumn' | 'winter' | 'spring' | null {
  const date1 = new Date(d1);
  const date2 = new Date(d2);

  if (
    date1.getMonth() === 11 ||
    date1.getMonth() === 0 ||
    date1.getMonth() === 1 ||
    date2.getMonth() === 11 ||
    date2.getMonth() === 0 ||
    date2.getMonth() === 1
  ) {
    return 'winter';
  }

  if (
    (date1.getMonth() >= 9 && date1.getMonth() <= 10) ||
    (date2.getMonth() >= 9 && date2.getMonth() <= 10)
  ) {
    return 'autumn';
  }

  if (
    (date1.getMonth() >= 2 && date1.getMonth() <= 3) ||
    (date2.getMonth() >= 2 && date2.getMonth() <= 3)
  ) {
    return 'spring';
  }

  if (
    (date1.getMonth() >= 4 && date1.getMonth() <= 8) ||
    (date2.getMonth() >= 4 && date2.getMonth() <= 8)
  ) {
    return 'summer';
  }

  return null;
}

const schoolAndWeekstayTimeOptions = {
  arrival: [
    { name: 'early', title: '09:00 - 11:00 (anticipée)' },
    { name: 'normal', title: '11:00 - 13:00' },
    { name: 'late', title: '13:00 - 15:00 (tardive)' },
  ],
  departure: [
    { name: 'early', title: '09:00 - 11:00 (anticipé)' },
    { name: 'normal', title: '11:00 - 13:00' },
    { name: 'late', title: '13:00 - 15:00 (tardif)' },
  ],
};

const vacationTimeOptions = {
  arrival: [
    { name: 'early', title: '13:00 - 15:00 (anticipée)' },
    { name: 'normal', title: '15:00 - 17:00' },
    { name: 'late', title: '17:00 - 19:00 (tardive)' },
  ],
  departure: [
    { name: 'early', title: '08:00 - 10:00 (anticipé)' },
    { name: 'normal', title: '10:00 - 12:00' },
    { name: 'late', title: '12:00 - 14:00 (tardif)' },
  ],
};

const weekendFriTimeOptions = {
  arrival: [
    { name: 'early', title: '16:00 - 17:00 (anticipée)' },
    { name: 'normal', title: '17:00 - 18:00' },
    { name: 'late', title: '18:00 - 19:00 (tardive)' },
  ],
  departure: [
    { name: 'early', title: '11:00 - 13:00 (anticipé)' },
    { name: 'normal', title: '13:00 - 15:00' },
    { name: 'late', title: '15:00 - 17:00 (tardif)' },
  ],
};

const weekendSatTimeOptions = {
  arrival: [
    { name: 'early', title: '9:00 - 10:00 (anticipée)' },
    { name: 'normal', title: '10:00 - 11:00' },
    { name: 'late', title: '11:00 - 13:00 (tardive)' },
  ],
  departure: [
    { name: 'early', title: '11:00 - 13:00 (anticipé)' },
    { name: 'normal', title: '13:00 - 15:00' },
    { name: 'late', title: '15:00 - 17:00 (tardif)' },
  ],
};

export function getTimeOptionsData() {
  return [
    schoolAndWeekstayTimeOptions,
    vacationTimeOptions,
    weekendFriTimeOptions,
    weekendSatTimeOptions,
  ];
}

const simpleSchoolAndWeekstayTimeOptions = {
  arrival: {
    early: '09:00 - 11:00 (anticipée)',
    normal: '11:00 - 13:00',
    late: '13:00 - 15:00 (tardive)',
  },

  departure: {
    early: '09:00 - 11:00 (anticipé)',
    normal: '11:00 - 13:00',
    late: '13:00 - 15:00 (tardif)',
  },
};

const simpleVacationTimeOptions = {
  arrival: {
    early: '13:00 - 15:00 (anticipée)',
    normal: '15:00 - 17:00',
    late: '17:00 - 19:00 (tardive)',
  },

  departure: {
    early: '08:00 - 10:00 (anticipé)',
    normal: '10:00 - 12:00',
    late: '12:00 - 14:00 (tardif)',
  },
};

const simpleWeekendFriTimeOptions = {
  arrival: {
    early: '16:00 - 17:00 (anticipée)',
    normal: '17:00 - 18:00',
    late: '18:00 - 19:00 (tardive)',
  },
  departure: {
    early: '11:00 - 13:00 (anticipé)',
    normal: '13:00 - 15:00',
    late: '15:00 - 17:00 (tardif)',
  },
};

const simpleWeekendSatTimeOptions = {
  arrival: {
    early: '9:00 - 10:00 (anticipée)',
    normal: '10:00 - 11:00',
    late: '11:00 - 13:00 (tardive)',
  },
  departure: {
    early: '11:00 - 13:00 (anticipé)',
    normal: '13:00 - 15:00',
    late: '15:00 - 17:00 (tardif)',
  },
};

export function getSimpleTimeOptionsData() {
  return [
    simpleSchoolAndWeekstayTimeOptions,
    simpleVacationTimeOptions,
    simpleWeekendFriTimeOptions,
    simpleWeekendSatTimeOptions,
  ];
}

export function getTimeString(
  time: 'early' | 'normal' | 'late',
  coming: 'arrival' | 'departure',
  goal: 'school' | 'vacation' | 'weekend' | 'weekstay',
  fridayArrival: boolean
): string {
  switch (goal) {
    case 'vacation':
      return getSimpleTimeOptionsData()[1][coming][time];

    case 'weekend':
      return getSimpleTimeOptionsData()[fridayArrival ? 2 : 3][coming][time];

    default:
      return getSimpleTimeOptionsData()[0][coming][time];
  }
}
