import React from 'react';

import { useTranslation } from 'react-i18next';
import { FormInputsType } from '../../input/FormType';
import { formatDate, getTimeString } from '../../helpers/DateHelper';
import { getAnimationsNb, getTotalPeople, getVisitsNb } from '../../helpers/PriceHelper';
import { SummaryType } from '../../input/SummaryType';
import TextInput from '../../input/TextInput';

type SummaryProps = FormInputsType & {
  summary: SummaryType;
  updateFields: (fields: Partial<FormInputsType>) => void;
};

export default function Summary(props: SummaryProps) {
  const { t } = useTranslation('', { keyPrefix: 'Config' });

  const getPeopleTotal = (): number => +props.kidsNb + +props.teensNb + +props.adultsNb;

  const chf = new Intl.NumberFormat('en-CH', {
    style: 'currency',
    currency: 'CHF',
  });

  const chfSimple = new Intl.NumberFormat('en-CH', {
    style: 'currency',
    currency: 'CHF',
  });

  const tGruyere = {
    simple: t('visits.simple'),
    guided: t('visits.guided'),
    narrated: t('visits.narrated'),
    treasure: t('visits.treasure'),
  };

  // console.log(props);

  return (
    <section className="configuratorStep container medTight">
      <h2>{t('summary.title')}</h2>
      <h4>{t('summary.subtitle')}</h4>
      <article className="priceCard priceCard--summary">
        <div className="priceCard__header">
          <h3>{t(`generalInfos.typeOfVisitor.${props.goalOfStay}`)}</h3>
          <p>
            {t('summary.from')} {formatDate(props.arrivalDate)} {t('summary.to')}{' '}
            {formatDate(props.departureDate)}
            <br />
            <small>
              {t('stayInfos.arrivalTimeShort')}:{' '}
              {getTimeString(props.arrivalTime, 'arrival', props.goalOfStay, props.fridayArrival)} |{' '}
              {t('stayInfos.departureTimeShort')}{' '}
              {getTimeString(
                props.departureTime,
                'departure',
                props.goalOfStay,
                props.fridayArrival
              )}
            </small>
          </p>
          {/* <small>{t(`generalInfos.typeOfStay.${props.typeOfStay}`)}</small> */}
        </div>
        <h3 className="priceCard__price">
          <span className="total">
            {t('total')} <small>({t('taxIncluded')})</small>
          </span>
          <span className="chf">{chf.format(props.summary.totalPrice)}</span>
        </h3>
        <div className="priceCard__body">
          <div className="summary__elt">
            <span className="title">
              <b>{t('summary.stay')}</b>:{' '}
              {props.goalOfStay === 'weekend'
                ? t('summary.flatRate')
                : t('summary.nbPeople', {
                    totalNights: props.summary.totalNights,
                    totalPeople: getPeopleTotal(),
                  })}
              {props.summary.minPeoplePerNight >
                props.summary.totalNights * getTotalPeople(props) &&
              props.goalOfStay !== 'weekend' ? (
                <>
                  <br />
                  <small>
                    {t('summary.minNuites', {
                      minPeoplePerNight: props.summary.minPeoplePerNight,
                    })}
                  </small>
                </>
              ) : (
                ''
              )}
            </span>
            <span className="price">
              <b>{chfSimple.format(props.summary.stayPrice)}</b>
            </span>
          </div>
          <hr />

          {/* Seulement pour les WE */}
          {props.goalOfStay === 'weekend' && props.summary.supplementsPrice ? (
            <>
              <div className="summary__elt">
                <span className="title">
                  {t('step21.extras')} :{' '}
                  {+props.addDorms ? (
                    <span>
                      {props.addDorms} {t('step21.dorms')}
                    </span>
                  ) : (
                    ''
                  )}
                  {+props.addRooms ? (
                    <span>
                      {props.addRooms} {t('step21.rooms')}
                    </span>
                  ) : (
                    ''
                  )}
                  {props.thirdFloor && <span>{t('step21.3rdFloorTitle')}</span>}
                  {props.discoMaterial && <span>{t('step21.discoMat')}</span>}
                  {props.earlyAccess && (
                    <span>
                      {t('step21.earlyAccess')}{' '}
                      {+props.earlyNights ? (
                        <>
                          ({props.earlyNights} {t('step21.nuites')})
                        </>
                      ) : (
                        ''
                      )}
                    </span>
                  )}
                </span>
                <span className="price">{chfSimple.format(props.summary.supplementsPrice)}</span>
              </div>
              <hr />
            </>
          ) : (
            ''
          )}

          {props.goalOfStay === 'weekend' && (
            <>
              <div className="summary__elt">
                <span className="title">{t('summary.accessCharges')}</span>
                <span className="price">{chfSimple.format(props.summary.accessChargesPrice)}</span>
              </div>
              <hr />
            </>
          )}

          <div className="summary__elt">
            <span className="title">{t('summary.tax')}</span>
            <span className="price">{chfSimple.format(props.summary.taxPrice)}</span>
          </div>
          <hr />

          {props.goalOfStay !== 'weekend' && (
            <>
              <div className="summary__elt">
                <span className="title">
                  <b>{t(`generalInfos.typeOfStay.${props.typeOfStay}`)}</b>

                  {props.typeOfStay === 'fullBoard' && (
                    <>
                      <br />
                      <small className="s">
                        Repas supplémentaire(s):{' '}
                        {`${props.mealAtArrival ? "à l'arrivée" : ''} ${
                          props.mealAtArrival && props.mealOnDeparture ? '&' : ''
                        } ${props.mealOnDeparture ? 'au départ' : ''}`}
                      </small>
                    </>
                  )}
                </span>
                <span className="price">
                  <b>{chfSimple.format(props.summary.typeOfStayPrice)}</b>
                </span>
              </div>
              <hr />
            </>
          )}

          {props.goalOfStay !== 'weekend' &&
          props.introMaterial &&
          !props.introAnimations &&
          props.summary.materialPrice !== 0 ? (
            <>
              <div className="summary__elt">
                <span className="title">
                  {t('step2.mat')}

                  <br />
                  <small className="s">
                    {props.discoMat && <span>Disco</span>}
                    {props.cinemaMat && <span>Cinéma</span>}
                    {props.sportsMat && <span>Sports</span>}
                  </small>
                </span>
                <span className="price">{chfSimple.format(props.summary.materialPrice)}</span>
              </div>
              <hr />
            </>
          ) : (
            ''
          )}

          {props.goalOfStay !== 'weekend' && props.activityPack !== null ? (
            <>
              <div className="summary__elt">
                <span className="title">
                  {t('summary.activities')} : {t(`packs.${props.activityPack}.title`)}
                  {props.mtbs && props.activityPack !== 'classique' ? '+' : ''} »
                </span>
                <span className="price">{chfSimple.format(props.summary.activitiesPrice)}</span>
              </div>
              <hr />
            </>
          ) : (
            ''
          )}

          {props.goalOfStay !== 'weekend' &&
          props.activityPack === 'l' &&
          getAnimationsNb(props) ? (
            <>
              <div className="summary__elt">
                <span className="title">
                  {t('summary.animations')}
                  <br />
                  <small className="s">
                    {props.animations_bow && <span>{t('animations.bow')}</span>}
                    {props.animations_mtb && <span>{t('animations.mtb')}</span>}
                    {props.animations_nature && <span>{t('animations.nature')}</span>}
                    {props.animations_forest && <span>{t('animations.forest')}</span>}
                    {props.animations_biathlon && <span>{t('animations.biathlon')}</span>}
                    {props.animations_olympiade && <span>{t('animations.olympiade')}</span>}
                    {props.animations_jeuDeLOie && <span>{t('animations.jeuDeLOie')}</span>}
                  </small>
                </span>
                <span className="price">{chfSimple.format(props.summary.animationsPrice)}</span>
              </div>
              <hr />
            </>
          ) : (
            ''
          )}

          {props.activityPack === 'classique' && (
            <>
              <div className="summary__elt">
                <span className="title">
                  {t('summary.animations')}
                  <br />
                  <small className="s">{t('summary.animationsClassique')}</small>
                </span>
                <span className="price">{chfSimple.format(props.summary.animationsPrice)}</span>
              </div>
              <hr />
            </>
          )}

          {props.goalOfStay !== 'weekend' &&
          (props.activityPack === 'l' || props.activityPack === 'classique') &&
          getVisitsNb(props) ? (
            <>
              <div className="summary__elt">
                <span className="title">
                  {t('visits.title')}
                  <br />
                  <small className="s">
                    {props.visits_electrobroc && <span>{t('visits.electrobroc')}</span>}
                    {props.visits_maisonDuGruyere && <span>{t('visits.mdg')}</span>}
                    {props.visits_maisonCailler && <span>{t('visits.mc')}</span>}
                    {props.visits_chateauDeGruyere && (
                      <span>
                        {t('visits.gruyeres')}: {tGruyere[props.visits_chateauDeGruyereDetails]},{' '}
                        {props.visits_classNb} {t('visits.classes')}
                      </span>
                    )}
                  </small>
                </span>
                <span className="price">{chfSimple.format(props.summary.visitsPrice)}</span>
              </div>
              <hr />
            </>
          ) : (
            ''
          )}

          {props.goalOfStay !== 'weekend' && props.summary.mtbsPrice !== 0 ? (
            <>
              <div className="summary__elt">
                <span className="title">
                  {t('mtbs.title')} <br />
                  <small className="s">
                    {props.bikeNb} vélos pour {props.bikeDays} jours
                  </small>
                </span>
                <span className="price">{chfSimple.format(props.summary.mtbsPrice)}</span>
              </div>
              <hr />
            </>
          ) : (
            ''
          )}

          <div className="summary__elt">
            <span className="title">
              {t('step4.cleaning')}
              <br />
              <small className="s">
                {props.typeOfStay === 'fullBoard' || props.cleaning
                  ? t('step4.cleaningAtTheEnd')
                  : ''}
                {(props.typeOfStay === 'fullBoard' || props.cleaning) && props.everyDayCleaning
                  ? t('step4.cleaningEveryDay')
                  : ''}
                {props.typeOfStay !== 'fullBoard' && !props.cleaning
                  ? t('step4.cleaningByRenter')
                  : ''}
                .
              </small>
            </span>
            <span className="price">{chfSimple.format(props.summary.cleaningPrice)}</span>
          </div>
        </div>
      </article>

      <article>
        <TextInput
          title={t('summary.remarks')}
          name="remarks"
          textarea
          validation={{ regex: undefined }}
          updateSingleField={props.updateFields}
          value={props.remarks}
        />
      </article>

      <article className="summary--footer">
        <p>
          <b>{t('summary.disclaimerTitle')}</b>
        </p>
        <p>{t('summary.disclaimerRow1')}</p>
        <p>{t('summary.disclaimerRow2')}</p>
        <p>{t('summary.disclaimerRow3')}</p>
        <p>{t('summary.disclaimerRow4')}</p>
      </article>
    </section>
  );
}
