import React from 'react';
import { useTranslation } from 'react-i18next';
import Hero from '../components/Hero';
import ImgButton from '../components/ImgButton';

export default function Error404() {
  const { t } = useTranslation('', { keyPrefix: 'Error404' });

  return (
    <main id="error404">
      <Hero page="Error404" imgPath="../assets/layouts/hero-test-cover.jpg" />
      <section className="container">
        <h3>{t('sorryMessage')}</h3>
        <ImgButton to="/" label={t('link')} picto="maison_white" btnType="btn--primary" />
      </section>
    </main>
  );
}
