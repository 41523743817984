import React from 'react';
import { Link } from 'react-router-dom';

type CheckboxInputType = {
  title: string;
  name: string;
  checked?: boolean;
  disabled?: boolean;
  extLink?: string;
};

type CheckboxInputProps = CheckboxInputType & {
  updateSingleField: (fields: Partial<CheckboxInputType>) => void;
};

export default function CheckboxInput({
  title,
  name,
  checked,
  disabled,
  extLink,
  updateSingleField,
}: CheckboxInputProps) {
  return (
    <div className={`input input__checkbox ${disabled ? 'input__checkbox--disabled' : ''}`}>
      <label htmlFor={name}>
        <input
          type="checkbox"
          name={name}
          id={name}
          onChange={() => updateSingleField({ [name]: !checked })}
          checked={checked}
          disabled={disabled}
        />
        <span className="checkmark" />
        {title}
      </label>
      {extLink && (
        <Link
          to={extLink}
          target="_blank"
          rel="noopener noreferrer"
          title="Vers le site du prestataire"
        >
          <i className="fa-solid fa-arrow-up-right-from-square" />
        </Link>
      )}
    </div>
  );
}
