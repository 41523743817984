/* eslint-disable react/jsx-props-no-spreading */
import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useMultistepForm from '../../useMultistepForm';
import Hero from '../../components/Hero';
import Step1 from './Step1';
import Summary from './Summary';
import ProgressBar from '../../components/ProgressBar';
import Step2 from './Step2';
import {
  DateDifference,
  Today,
  AddDays,
  GetSeason,
  GetAccessChargesSeason,
} from '../../helpers/DateHelper';
import {
  getAccessChargesPrice,
  getActivitiesPrice,
  getAnimationsPrice,
  getCleaningPrice,
  getMaterialPrice,
  getMinNuites,
  getMtbsPrice,
  getNightsPrice,
  getPricePP,
  getTaxPrice,
  getTotalPrice,
  getTypeOfStayPrice,
  getVisitsPrice,
  getWEPrice,
  getWESupplementsPrice,
} from '../../helpers/PriceHelper';

import { FormInputsType } from '../../input/FormType';
import { SummaryType } from '../../input/SummaryType';
import Step21 from './Step21';
import Step3 from './Step3';
import Step4 from './Step4';
import sendingRequest from '../../helpers/FormHelper';
import FormStatus from '../../components/FormStatus';

const INITIAL_DATA: FormInputsType = {
  // generalInfos
  name: '',
  phone: '',
  mail: '',
  kidsNb: 0,
  teensNb: 0,
  adultsNb: 0,
  goalOfStay: 'school',
  // Checking if today is a Friday, so default is set correctly
  fridayArrival: new Date().getDay() === 5,
  saturdayArrival: new Date().getDay() === 6,

  // New data
  title: '-',
  address: '',
  nip: undefined,
  location: '',
  country: '',
  company: '',

  // stayInfos
  typeOfStay: 'autonomous',
  arrivalDate: Today(),
  arrivalTime: 'normal',
  departureDate: AddDays(Today(), 1),
  departureTime: 'normal',
  season: GetSeason(Today(), AddDays(Today(), 1)),
  heating: GetAccessChargesSeason(Today(), AddDays(Today(), 1)),

  // intro
  introMaterial: true,
  introAnimations: true,
  introCleaning: true,

  // Step 2
  mtbs: true,
  discoMat: true,
  cinemaMat: true,
  sportsMat: true,
  bikeNb: 0,
  bikeDays: 0,

  // Step 2.1
  forfait: 'xxl',
  thirdFloor: false,
  discoMaterial: false,
  earlyAccess: false,
  earlyNights: 0,
  addDorms: 0,
  addRooms: 0,

  // Step 3
  activityPack: 'l',

  animations_bow: false,
  animations_mtb: false,
  animations_nature: false,
  animations_forest: false,
  animations_biathlon: false,
  animations_olympiade: false,
  animations_jeuDeLOie: false,

  visits_electrobroc: false,
  visits_maisonDuGruyere: false,
  visits_maisonCailler: false,
  visits_chateauDeGruyere: false,
  visits_chateauDeGruyereDetails: 'simple',
  visits_classNb: 0,

  // Step 4
  mealAtArrival: false,
  mealOnDeparture: true,
  cleaning: false,
  everyDayCleaning: false,

  // Summary
  remarks: '',
};

export default function ConfiguratorV2() {
  const { t } = useTranslation('', { keyPrefix: 'Config' });

  // States
  const [data, setData] = useState<FormInputsType>(INITIAL_DATA);
  const [summary, setSummary] = useState<SummaryType>({
    totalPrice: 0,
    stayPrice: 0,
    supplementsPrice: 0,
    accessChargesPrice: 0,
    taxPrice: 0,
    typeOfStayPrice: 0, // pension
    materialPrice: 0,
    activitiesPrice: 0,
    animationsPrice: 0,
    visitsPrice: 0,
    cleaningPrice: 0,

    totalNights: 0,
    mtbsPrice: 0,

    minNuites: 0,
    minPeoplePerNight: 0,
  });
  const [totalNights, setTotalNights] = useState<number>(0);
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [pricePP, setPricePP] = useState<number | null>(0);
  const [status, setStatus] = useState<'sending' | 'success' | 'error' | 'cooldown'>();

  const getTotalNights = (): number => DateDifference(data.arrivalDate, data.departureDate);

  // Updates a selected part of the data object
  const updateFields = (fields: Partial<FormInputsType>) => {
    setData((prev) => ({ ...prev, ...fields }));
  };

  const updateSummary = () => {
    setSummary({
      totalPrice: getTotalPrice(data, getTotalNights()),

      stayPrice:
        data.goalOfStay === 'weekend'
          ? getWEPrice(data, getTotalNights())
          : getNightsPrice(data, getTotalNights()),
      supplementsPrice: getWESupplementsPrice(data, getTotalNights()),

      accessChargesPrice: getAccessChargesPrice(data, getTotalNights()),

      taxPrice: getTaxPrice(data, getTotalNights()),
      typeOfStayPrice: getTypeOfStayPrice(data, getTotalNights()), // pension

      materialPrice: getMaterialPrice(data),
      activitiesPrice: getActivitiesPrice(data),
      animationsPrice: getAnimationsPrice(data),
      visitsPrice: getVisitsPrice(data),

      cleaningPrice: getCleaningPrice(data, getTotalNights()),

      totalNights: getTotalNights(),
      mtbsPrice: getMtbsPrice(data),

      minNuites: getMinNuites(
        data.goalOfStay,
        data.season,
        DateDifference(data.arrivalDate, data.departureDate),
        +data.kidsNb + +data.teensNb + +data.adultsNb
      ),
      minPeoplePerNight: Math.ceil(
        getMinNuites(
          data.goalOfStay,
          data.season,
          DateDifference(data.arrivalDate, data.departureDate),
          +data.kidsNb + +data.teensNb + +data.adultsNb
        ) / DateDifference(data.arrivalDate, data.departureDate)
      ),
    });
  };

  const updatePeople = (fields: Partial<FormInputsType>) => {
    setData((prev) => ({ ...prev, ...fields }));
  };

  const updateGoalOfStay = (fields: Partial<FormInputsType>) => {
    if (fields.goalOfStay === 'weekend') updateFields({ typeOfStay: 'autonomous' });
    setData((prev) => ({ ...prev, ...fields }));
  };

  const updateThirdFloor = (fields: Partial<FormInputsType>) => {
    if (fields.discoMaterial) {
      updateFields({ thirdFloor: true });
    } else {
      updateFields({ thirdFloor: false });
    }
    setData((prev) => ({ ...prev, ...fields }));
  };

  const updateIntro = (fields: Partial<FormInputsType>) => {
    if (fields.introMaterial !== undefined) {
      // Resetting Material related fields
      if (fields.introMaterial === false) {
        updateFields({ activityPack: data.introAnimations ? 'l' : null });
      } else {
        updateFields({ activityPack: 's' });
      }

      updateFields({
        mtbs: true,
        discoMat: true,
        cinemaMat: true,
        sportsMat: true,
        bikeNb: 0,
        bikeDays: 0,
      });
    }
    if (fields.introAnimations !== undefined) {
      // Resetting all animations infos;
      if (fields.introAnimations === false) {
        updateFields({ activityPack: data.introMaterial ? 's' : null });
      } else {
        updateFields({ activityPack: 'l' });
      }
      updateFields({
        animations_bow: false,
        animations_mtb: false,
        animations_nature: false,
        animations_forest: false,
        animations_biathlon: false,
        animations_olympiade: false,
        animations_jeuDeLOie: false,

        visits_electrobroc: false,
        visits_maisonDuGruyere: false,
        visits_maisonCailler: false,
        visits_chateauDeGruyere: false,
        visits_chateauDeGruyereDetails: 'simple',
      });
    }
    if (fields.introCleaning !== undefined) {
      // Resetting all cleaning options.
      updateFields({
        mealAtArrival: false,
        mealOnDeparture: true,
        cleaning: false,
        everyDayCleaning: false,
      });
    }
    setData((prev) => ({ ...prev, ...fields }));
  };

  const updateDates = (name: string, e: React.ChangeEvent<HTMLInputElement>) => {
    const dDate = new Date(data.departureDate);

    const friSatArrivals = ['fridayArrival', 'saturdayArrival'];

    if (name === 'arrivalDate') {
      // Checking if arrival on Friday or Saturday
      if (new Date(e.target.value).getDay() === 5) {
        updateFields({ [friSatArrivals[0]]: true, [friSatArrivals[1]]: false });
      } else if (new Date(e.target.value).getDay() === 6) {
        updateFields({ [friSatArrivals[0]]: false, [friSatArrivals[1]]: true });
      } else {
        updateFields({ [friSatArrivals[0]]: false, [friSatArrivals[1]]: false });
      }
    }

    // If arrival date after departure date
    if (name === 'arrivalDate' && new Date(e.target.value).getTime() > dDate.getTime()) {
      const dName = 'departureDate';
      updateFields({ [dName]: e.target.value });
    }

    updateFields({
      season:
        name === 'arrivalDate'
          ? GetSeason(e.target.value, data.departureDate)
          : GetSeason(data.arrivalDate, e.target.value),

      heating:
        name === 'arrivalDate'
          ? GetAccessChargesSeason(e.target.value, data.departureDate)
          : GetAccessChargesSeason(data.arrivalDate, e.target.value),
    });

    updateFields({ [name]: e.target.value });
  };

  useEffect(() => {
    const total = getTotalPrice(data, DateDifference(data.arrivalDate, data.departureDate));
    // updateSummary();
    setTotalPrice(total);
    setPricePP(getPricePP(total, +data.kidsNb + +data.teensNb + +data.adultsNb) || null);
    // console.log('Total Price =', total);
  }, [updateFields]);

  useEffect(() => {
    // Recalulating nights
    const dateInterval = DateDifference(data.arrivalDate, data.departureDate);
    setTotalNights(dateInterval * (+data.kidsNb + +data.teensNb + +data.adultsNb));
  }, [updateDates, updatePeople]);

  const alertUser = (e: BeforeUnloadEvent) => {
    if (status !== 'success') {
      e.preventDefault();
      e.returnValue = '';
    }
  };

  // Warn user that he shouldn't use refresh or return.
  useEffect(() => {
    window.addEventListener('beforeunload', alertUser);
    return () => {
      window.removeEventListener('beforeunload', alertUser);
    };
  }, []);

  const formBuilder = (): ReactElement[] => {
    const multiStepForm = [];

    multiStepForm.push(
      <Step1
        {...data}
        updateFields={updateFields}
        updateDates={updateDates}
        updatePeople={updatePeople}
        updateIntro={updateIntro}
        updateGoalOfStay={updateGoalOfStay}
        totalNights={totalNights}
      />
    );

    // Si c'est juste un weekend : 21
    if (data.goalOfStay === 'weekend') {
      multiStepForm.push(
        <Step21 {...data} updateFields={updateFields} updateThirdFloor={updateThirdFloor} />
      );
    } else {
      // Si c'est un séjour :

      // Avec matériel & animation : 3
      if (data.introAnimations) multiStepForm.push(<Step3 {...data} updateFields={updateFields} />);

      // Avec matériel : 2
      if (data.introMaterial && !data.introAnimations)
        multiStepForm.push(<Step2 {...data} updateFields={updateFields} />);
    }

    if (data.introCleaning || data.typeOfStay === 'fullBoard')
      multiStepForm.push(<Step4 {...data} updateFields={updateFields} />);

    multiStepForm.push(<Summary {...data} summary={summary} updateFields={updateFields} />);

    return multiStepForm;
  };

  const { steps, currentStepIndex, step, isFirstStep, isLastStep, back, next } = useMultistepForm(
    formBuilder()
  );

  const backOneStep = () => {
    back();
    setStatus(undefined);
  };

  const onSubmit = async (e: FormEvent) => {
    e.preventDefault();

    if (!isLastStep) {
      next();
      updateSummary();
      return;
    }

    setStatus('sending');

    if (await sendingRequest({ ...data, ...summary }, 'https://eterpaz.rdox.ch/items/Formulaire')) {
      setStatus('success');
      // Resetting form
      updateFields(INITIAL_DATA);
    } else {
      setStatus('error');
    }
  };

  const currency = new Intl.NumberFormat('en-CH', { style: 'currency', currency: 'CHF' });
  const formatPrice = (price: number | null) => (price ? currency.format(price) : '-');

  return (
    <main id="configurator" className="bg--lightGreen">
      {currentStepIndex !== 0 && (
        <nav id="priceNav">
          <div className="container">
            <p>
              {t('total')}: <b>{formatPrice(totalPrice)}</b>
            </p>

            <h5>{t('priceWithOptions')}</h5>

            {data.goalOfStay !== 'weekend' && (
              <p>
                {t('perPerson')}: <b>{formatPrice(pricePP)}</b>{' '}
              </p>
            )}
          </div>
        </nav>
      )}

      <Hero
        page="Config"
        imgPath="../assets/layouts/config-cover.jpg"
        panorama="panorama-light-green"
      />

      <form onSubmit={onSubmit}>
        {status !== 'success' && step}

        <section className="pageBottom container medTight">
          {!isFirstStep && status !== 'success' && (
            <button type="button" className="btn btn--secondary" onClick={backOneStep}>
              {t(`btns.prev${currentStepIndex}`)}
            </button>
          )}

          {status !== 'success' && (
            <button type="submit" className="btn btn--primary">
              {isLastStep ? (
                <i className="fa-regular fa-envelope" />
              ) : (
                <i className="fa-solid fa-calendar-days" />
              )}

              {isLastStep ? t('btns.finish') : t(`btns.next${currentStepIndex}`)}
            </button>
          )}

          <FormStatus status={status} />

          {status !== 'success' && (
            <div className="progress">
              <p>
                {t('progress')} {currentStepIndex + 1} / {steps.length}
              </p>
              <ProgressBar stepsNb={steps.length} currentIndex={currentStepIndex} />
            </div>
          )}
        </section>
      </form>
    </main>
  );
}
